import * as http from '../helpers/http';
import { IProposicao } from '../models/Proposicao.Model';
import { capitalizeName } from '../utils/string.utils';
import { format } from 'date-fns'

class ProposicaoService {

  Get = async (id: number): Promise<IProposicao> => {
    const result = await http.get(`/proposicao/${id}`);
    if (result.success) { return result.data }
    return undefined;
  };

  GetList = async (filter?: any, pageSize?: Number, pageIndex?: Number): Promise<any> => {
    const result = await http.get(`/proposicao?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages
        },
        dataCards: result.data.data
        // result.data.data.map(item => {
        // return {
        //     id: item.id,
        //     nome: item.nome,
        //     mandatoTipo: item?.tratamento,
        //     partido: item?.sigla_partido,
        //     estadoFederacao: item?.uf_exercicio,
        //     seguindo: item.cliente ? item.cliente?.seguindo : false,
        //     qualificacao: item.cliente ? item.cliente?.qualificacao.toLowerCase() : "não classificado",
        //     statusMandato: item?.situacao,
        //     urlImg: item.url_foto ? item.url_foto.replace('http://', 'https://') : undefined,
        //     observacao: item.cliente ? item.cliente.observacao : ''
        // }
        // })
      }
    }
    return undefined;
  };


  GetSummary = async (dtIni: Date, dtFin: Date, filtro: any): Promise<any> => {
    const result = await http.get('/dashboard/proposicao/' + format(dtIni, 'yyyy-MM-dd') + '/' + format(dtFin, 'yyyy-MM-dd') + `?filter=${JSON.stringify(filtro)}`);
    if (result.success) { return result.data }
    return undefined;
  };

  GetSummaryWorkSpaceDadosGerais = async (dtIni: Date, dtFin: Date, filtro: any): Promise<any> => {
    const result = await http.get('/workspace/dadosgerais/' + format(dtIni, 'yyyy-MM-dd') + '/' + format(dtFin, 'yyyy-MM-dd') + `?filter=${JSON.stringify(filtro)}`);

    if (result.success) { return result.data }
    return undefined;
  };

  GetSummaryWorkSpaceSeguidosPendencias = async (dtIni: Date, dtFin: Date, filtro: any): Promise<any> => {
    const result = await http.get('/workspace/dadosgerais/seguidos/pendencias/' + format(dtIni, 'yyyy-MM-dd') + '/' + format(dtFin, 'yyyy-MM-dd') + `?filter=${JSON.stringify(filtro)}`);

    if (result.success) { return result.data }
    return undefined;
  };

  //   GetSummary = async (dtIni: Date, dtFin: Date): Promise<any> => {
  //     const result = await http.get('/dashboard/' + format(dtIni, 'yyyy-MM-dd') + '/' + format(dtFin, 'yyyy-MM-dd'));
  //     if (result.success) { return result.data }
  //     return undefined;
  // };

  Create = async (data: any): Promise<any> => {
    const result = await http.post(`/proposicao`, data);
    if (result.success) {
      return result.data
    }
    return undefined;
  };

  Delete = async (id: number): Promise<any> => {
    const result = await http.del(`/proposicao/${id}`);
    if (result.success) {
      return true
    }
    return false;
  };

  Update = async (id: number, data: any): Promise<any> => {
    const result = await http.put(`/proposicao/${id}`, data);

    if (result.success) {
      return result.data
    }
    return undefined;
  };

  GetPartialDescriptionAndHouse = async (name: string, filter: { key: string, value: any }[], withId?: boolean): Promise<any> => {
    const pageSize: number = 10;
    const pageIndex: number = 1;
    const newFilter = [...filter, { key: 'casaEdesc', value: name }];
    const result = await http.get(`/proposicao?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      let res = []
      if (withId) res = result.data.data
      else
        res = result.data.data.map(item => item.descricao.toUpperCase())
      return res
    }
    return undefined;
  };

  GetPartialDescription = async (name: string, filter: { key: string, value: any }[], withId?: boolean): Promise<any> => {
    const pageSize: number = 10;
    const pageIndex: number = 1;
    const newFilter = [...filter, { key: 'descP', value: name }];
    const result = await http.get(`/proposicao?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      let res = []
      if (withId) res = result.data.data
      else
        res = result.data.data.map(item => item.descricao.toUpperCase())

      return res

    }
    return undefined;
  };



  GetPartialAutor = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
    const pageSize: number = 10;
    const pageIndex: number = 1;
    let newFilter = [...filter, { key: 'autorP', value: name }];
    const result = await http.get(`/proposicao?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

    if (result.success) {
      const res = result.data.data.map(item => item?.autor_parcial?.nome)
      return res
    }
    return undefined;
  };

  GetPartialRelator = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
    const pageSize: number = 10;
    const pageIndex: number = 1;
    let newFilter = [...filter, { key: 'relatorP', value: name }];

    const result = await http.get(`/proposicao?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return result.data.data
    }
    return undefined;
  };


  GetPartialInfluenciado = async (name: string, filter: { key: string, value: any }[]): Promise<any> => {
    const pageSize: number = 10;
    const pageIndex: number = 1;
    let newFilter = [...filter, { key: 'influenciadoP', value: name }];
    const result = await http.get(`/proposicao?filter=${JSON.stringify(newFilter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return result.data.data
    }
    return undefined;
  };

  SetAnalise = async (data: any): Promise<boolean> => {
    const result = await http.put(`/proposicao`, data);
    if (result.success) { return true }
    return false;
  };

  SetImpacto = async (id: number, data: any): Promise<boolean> => {
    const result = await http.put(`/proposicao/${id}/impacto`, data);
    if (result.success) { return true }
    return false;
  };


  GetPosicionamentoUnidadeNegocio = async (id: number): Promise<any> => {

    const result = await http.get(`/proposicao/${id}/posicionamentoUnidadeNegocio`);
    if (result.success) { return result }
    return undefined;
  };

  SetPosicionamentoUnidadeNegocio = async (id: number, data: any): Promise<any> => {
    const result = await http.put(`/proposicao/${id}/posicionamentoUnidadeNegocio`, data);
    if (result.success) { return result }
    return false;
  };

  SetSubtema = async (id: number, id_subtema: number): Promise<any> => {
    const result = await http.put(`/proposicao/${id}/tema`, { id_subtema: id_subtema });
    if (result.success) { return result.data }
    return undefined;
  };

  SetTopico = async (id: number, id_topico: number, id_subtema: number): Promise<any> => {
    const result = await http.put(`/proposicao/${id}/subtema`, { id_topico: id_topico, id_subtema: id_subtema });
    if (result.success) { return result.data }
    return undefined;
  };

  SetSeguir = async (id: number, value: boolean, path: string): Promise<any> => {
    const result = await http.put(`/proposicao/${id}/seguir`, { seguindo: value, path: path });
    if (result.success) { return result.data }
    return undefined;
  };

  // GetTramitacao = async (id: number): Promise<any> => {
  //   const result = await http.get(`/proposicao/${id}/tramitacao`);
  //   if (result.success) { return result.data }
  //   return undefined;
  // }

  GetTramitacaoUni = async (idProp: number, id: number): Promise<any> => {
    const result = await http.get(`/proposicao/${idProp}/tramitacao/${id}`);
    if (result.success) { return result }
    return undefined;
  }

  GetTramitacao = async (id: number, pageIndex: number, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/tramitacao?pageSize=${pageSize}&pageIndex=${pageIndex}`);

    if (result.success) return {

      success: true,
      listInfo: {
        pageSize: result.data.pageSize,
        pageIndex: result.data.pageIndex,
        pageItens: result.data.pageItens,
        totalItens: result.data.totalItens,
        totalPages: result.data.totalPages
      },
      lista: result.data.data
    }

    return undefined;
  }

  GetTramitacaoNoPage = async (id: number): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/tramitacaoGet`);

    if (result.success) return {

      success: true,
      listInfo: {
        totalItens: result.data.totalItens,
      },
      lista: result.data.data
    }

    return undefined;
  }

  /* --- filtro proposicao lista --- */

  registrarUltimoFiltroEscolhido = async (id_filtro: number): Promise<any> => {
    const result = await http.post(`/proposicao/update/filtro/${id_filtro}`);
    if (result.success) { return result.data }
    return 0;
  };

  salvarFiltroPropLista = async (data: any): Promise<any> => {
    const result = await http.post(`/proposicao/filtro`, data);
    if (result.success) { return result.data }
    return 0;
  };

  deleteFiltroPropLista = async (id: number): Promise<any> => {
    const result = await http.post(`/proposicao/filtro/${id}`);
    if (result.success) { return result.data }
    return 0;
  };

  GetFiltrosSalvos = async (idUsuario: number): Promise<any> => {
    const result = await http.get(`/proposicao/filtro/${idUsuario}`);
    if (result.success) { return result.data }
    return undefined;
  };



  /* --- Tramitação --- */

  CreateTramitacao = async (data: any, id: number): Promise<number> => {
    const result = await http.post(`/proposicao/${id}/tramitacao`, data);
    if (result.success) { return result.data }
    return 0;
  };

  DeleteTramitacao = async (id: number): Promise<boolean> => {
    const result = await http.del(`/proposicao/tramitacao/${id}`);
    if (result.success) { return true }
    return false;
  };

  UpdateTramitacao = async (data: any, idprop: number, id: number): Promise<boolean> => {
    const result = await http.put(`/proposicao/${idprop}/tramitacao/${id}`, data);
    if (result.success) { return result.success }
    return false;
  };

  /* --- Time Line --- */

  // this.app
  // .route(`/proposicao/:id/timeline`)
  // .get(isAnyLevel, (req: express.Request, res: express.Response) =>
  //   new ProposicaoTimeLineGetController(req._key, req._LoggedUser)
  //     .get(Number(req.params.id))
  //     .then((result) => res.status(200).json(result))
  //     .catch((error) => res.status(400).json(error))
  // );


  GetTimeLine = async (id: number): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/timeline`);
    if (result.success) return {
      success: true,
      lista: result.data
    }
    return undefined;
  }

  /* --- Apensada --- */

  GetListApensada = async (id: number, filter?: any, pageIndex?: number, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/apensadas?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages,
          totalNoFilter: result.data.totalNoFilter
        },
        listData: result.data.data.map(item => {
          return {
            id: item.id,
            descricao: item.descricao,
            apelido: item.apelido,
            ementa: item.ementa,
            data: item.data,
            casa: item.casa,
            proposicao_id: item.proposicao_id,
            tipo_relacao: item.tipo_relacao,
            prioridade: item.prioridade,
            posicao: item.posicao,
            tipoOutros: item.tipoOutros,
            autores: item.autores
          }
        })
      }
    }
    return undefined;
  }

  CreateApensada = async (id: number, data: any): Promise<any> => {
    const result = await http.post(`/proposicao/${id}/apensadas`, data);
    if (result.success) { return result.data }
    return 0;
  }

  GerarCorrelata = async (id: number): Promise<any> => {

    const result = await http.post(`/proposicao/${id}/correlatas`);
    if (result.success) {
      return result.data
    }
    return 0;
  }

  DeleteApensada = async (id: number, idApensada: number): Promise<boolean> => {
    const result = await http.del(`/proposicao/${id}/apensadas/${idApensada}`);
    if (result.success) { return true }
    return false;
  };

  /* --- Correlata --- */

  GetListCorrelata = async (id: number, filter?: any, pageIndex?: number, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/correlatas?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages,
          totalNoFilter: result.data.totalNoFilter
        },
        listData: result.data.data.map(item => {
          return {
            id: item.id,
            proposicao: item.proposicao,
            data: item.data,
            descricao: item.descricao,
            posicao: item.posicao,
            id_proposicao: item.id_proposicao
          }
        })
      }
    }
    return undefined;
  }


  DeleteCorrelata = async (idCorrelata: number): Promise<boolean> => {
    const result = await http.del(`/proposicao/correlata/${idCorrelata}`);
    if (result.success) { return true }
    return false;
  };

  SetPosicionamentoCorrelata = async (id: number, posicionamento: string): Promise<any> => {
    const result = await http.put(`/proposicao/correlatas/${id}/posicionamento`, { posicao: posicionamento });
    if (result.success) { return result.data }
    return undefined;
  }

  /* --- EMENDAS --- */

  SetPosicionamentoEmenda = async (id: number, posicionamento: string): Promise<any> => {
    const result = await http.put(`/proposicao/emendas/${id}/posicionamento`, { posicao: posicionamento });
    if (result.success) { return result.data }
    return undefined;
  }

  CreateEmenda = async (data: any, id: number): Promise<number> => {
    const result = await http.post(`/proposicao/${id}/emenda`, data);
    if (result.success) { return result.data }
    return 0;
  };

  // GetTextoEmenda = async (id: number): Promise<any> => {
  //   const result = await http.get(`/proposicao/${id}/emenda`);
  //   if (result.success) { return result.data }
  //   return undefined;
  // }

  GetEmendaTexto = async (id: number): Promise<any> => {
    const result = await http.get(`/proposicao/emendas/${id}/texto`);
    if (result.success) {
      return result.data;
    }
    return undefined;
  }

  GetEmenda = async (idEmenda: number): Promise<any> => {
    const result = await http.get(`/proposicao/emenda/${idEmenda}`);
    if (result.success) { return result.data }
    return undefined;
  };

  GetElk = async (word: String, data: any): Promise<any> => {
    const result = await http.post(`/proposicao/busca/${word ? word : undefined}`, data);
    if (result.success) { return result.data }
    return undefined;
  };

  GetElkGeralProposicao = async (word: String, data: any): Promise<any> => {
    const result = await http.post(`/proposicao/busca-geral-proposicao`, { texto: word, data });
    if (result.success) { return result.data }
    return undefined;
  };

  UpdateEmenda = async (data: any, idEmenda: number): Promise<boolean> => {
    const result = await http.put(`/proposicao/emenda/${idEmenda}`, data);
    if (result.success) { return result.success }
    return false;
  };

  DeleteEmenda = async (id: number): Promise<boolean> => {
    const result = await http.del(`/proposicao/emenda/${id}`);
    if (result.success) { return true }
    return false;
  };

  GetListEmenda = async (id: number, filter: any, pageIndex: number, pageSize: number = 8): Promise<any> => {
    const result = await http.get(`/proposicao/${id}/emendas?filter=${JSON.stringify(filter)}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    if (result.success) {
      return {
        success: true,
        listInfo: {
          pageSize: result.data.pageSize,
          pageIndex: result.data.pageIndex,
          pageItens: result.data.pageItens,
          totalItens: result.data.totalItens,
          totalPages: result.data.totalPages,
          totalNoFilter: result.data.totalNoFilter
        },
        listData: result.data.data.map(item => {
          return {
            id: item.id,
            proposicao: item.proposicao,
            data: item.data,
            ementa: item.ementa,
            posicionamento: item.posicionamento,
            autor: item.autores !== undefined ? item.autores : null,
            qualificacao: item.qualificacao !== undefined ? item.qualificacao : null,
          }
        })
      }
    }
    return undefined;
  }
}

export default new ProposicaoService();
