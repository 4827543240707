import * as http from '../helpers/http';

class InsightService {

    GetKey17 = async (dtIni: any, dtFin: any, siglaCasa: number, ambito: string): Promise<any> => {
        const result = await http.get(`/insights17/${dtIni}/${dtFin}/${siglaCasa}/${ambito}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }


    GetKey1 = async (dtIni: any, dtFin: any, ambito: string, siglaCasa: number, seguida: string): Promise<any> => {
        const result = await http.get(`/insights1/${dtIni}/${dtFin}/${ambito}/${siglaCasa}/${seguida}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey2 = async (dtIni: any, dtFin: any): Promise<any> => {
        const result = await http.get(`/insights2/${dtIni}/${dtFin}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey3Relatorias = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights3/relatorias/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {
            return result

        }
        return undefined;
    }
    GetKey3Autorias = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights3/autorias/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey4 = async (dtIni: any, dtFin: any, posicionamento: number, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights4/${dtIni}/${dtFin}/${posicionamento}/${esfera}/${siglaCasa}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey4Grafico = async (dtIni: any, dtFin: any, posicionamento: number, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights4Grafico/${dtIni}/${dtFin}/${posicionamento}/${esfera}/${siglaCasa}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey4ListIndefinido = async (dtIni: any, dtFin: any, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights4List/indefinido/${dtIni}/${dtFin}/${esfera}/${siglaCasa}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey4GraficoIndefinido = async (dtIni: any, dtFin: any, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights4Grafico/indefinido/${dtIni}/${dtFin}/${esfera}/${siglaCasa}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey5 = async (dtIni: any, dtFin: any, posicionamento: number, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights5/${dtIni}/${dtFin}/${posicionamento}/${esfera}/${siglaCasa}`);
        if (result.success) {
            return result

        }
        return undefined;
    }


    GetKey5Grafico = async (dtIni: any, dtFin: any, posicionamento: number, esfera: number, siglaCasa: number): Promise<any> => {
        const result = await http.get(`/insights5Grafico/${dtIni}/${dtFin}/${posicionamento}/${esfera}/${siglaCasa}`);
        if (result.success) {
            return result

        }
        return undefined;
    }




    GetKey6Grupo = async (dtIni: any, dtFin: any, idGrupo: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights6/grupos/${dtIni}/${dtFin}/${idGrupo}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey6Tema = async (dtIni: any, dtFin: any, idTema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights6/temas/${dtIni}/${dtFin}/${idTema}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey6Subtema = async (dtIni: any, dtFin: any, idSubtema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights6/subtemas/${dtIni}/${dtFin}/${idSubtema}/${esfera}`);
        if (result.success) {

            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey7Grupo = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights7/grupos/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey7Tema = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights7/temas/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey7Subtema = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights7/subtemas/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey8Grupo = async (dtIni: any, dtFin: any, idGrupo: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights8/${dtIni}/${dtFin}/${idGrupo}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey8Tema = async (dtIni: any, dtFin: any, idGrupo: number, idTema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights8/${dtIni}/${dtFin}/${idGrupo}/${idTema}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey8Subtema = async (dtIni: any, dtFin: any, idGrupo: number, idTema: number, idSubtema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights8/${dtIni}/${dtFin}/${idGrupo}/${idTema}/${idSubtema}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }


    GetKey9Grupo = async (dtIni: any, dtFin: any, idGrupo: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights9/grupos/${dtIni}/${dtFin}/${idGrupo}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey9Tema = async (dtIni: any, dtFin: any, idTema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights9/temas/${dtIni}/${dtFin}/${idTema}/${esfera}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey9Subtema = async (dtIni: any, dtFin: any, idSubtema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights9/subtemas/${dtIni}/${dtFin}/${idSubtema}/${esfera}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey10 = async (dtIni: any, dtFin: any, idProposicao: number): Promise<any> => {
        const result = await http.get(`/insights10/${dtIni}/${dtFin}/${idProposicao}`);
        if (result.success) {
            return result;
        }

        return undefined
    }
    GetKey11Casa = async (dtIni: any, dtFin: any, siglaCasa: string, esfera: number): Promise<any> => {
        const result = await http.get(`/insights11/${dtIni}/${dtFin}/${siglaCasa}/${esfera}`);

        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey11CasaGrafico = async (dtIni: any, dtFin: any, siglaCasa: string, esfera: number): Promise<any> => {
        const result = await http.get(`/insights11Grafico/${dtIni}/${dtFin}/${siglaCasa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey11Ambito = async (dtIni: any, dtFin: any, siglaCasa: string, ambito: string, esfera: number): Promise<any> => {
        const result = await http.get(`/insights11/${dtIni}/${dtFin}/${siglaCasa}/${ambito}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey11AmbitoGrafico = async (dtIni: any, dtFin: any, siglaCasa: string, ambito: string, esfera: number): Promise<any> => {
        const result = await http.get(`/insights11Grafico/${dtIni}/${dtFin}/${siglaCasa}/${ambito}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey12 = async (dtIni: any, dtFin: any, siglaCasa: string, idTema: number): Promise<any> => {
        const result = await http.get(`/insights12/${dtIni}/${dtFin}/${siglaCasa}/${idTema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey13AutoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/autoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey13AutoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/autoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey13AutoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/autoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey13RelatoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/relatoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey13RelatoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/relatoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey13RelatoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights13/relatoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey14CasaGrupo = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14Grupo/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey14CasaTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14Tema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey14CasaSubTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14SubTema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }



    GetKey14CasaGrupoGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14GrupoGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey14CasaTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14TemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey14CasaSubTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights14SubTemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey15Grupo = async (dtIni: any, dtFin: any, casa: number, idGrupo: number): Promise<any> => {
        const result = await http.get(`/insights15Grupo/${dtIni}/${dtFin}/${casa}/${idGrupo}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey15Tema = async (dtIni: any, dtFin: any, casa: number, idTema: number): Promise<any> => {
        const result = await http.get(`/insights15Tema/${dtIni}/${dtFin}/${casa}/${idTema
            }`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey15Subtema = async (dtIni: any, dtFin: any, casa: number, idSubtema: number): Promise<any> => {
        const result = await http.get(`/insights15Subtema/${dtIni}/${dtFin}/${casa}/${idSubtema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey16CasaGrupo = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16Grupo/${dtIni}/${dtFin}/${casa}/${esfera}`);

        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey16CasaTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16Tema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey16CasaSubTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16SubTema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }



    GetKey16CasaGrupoGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16GrupoGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey16CasaTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16TemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey16CasaSubTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights16SubTemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }


    GetKey18Grupo = async (dtIni: any, dtFin: any, estado: string, casa: number, idGrupo: number): Promise<any> => {
        const result = await http.get(`/insights18Grupo/${dtIni}/${dtFin}/${estado}/${casa}/${idGrupo}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey18Tema = async (dtIni: any, dtFin: any, estado: string, casa: number, idTema: number): Promise<any> => {
        const result = await http.get(`/insights18Tema/${dtIni}/${dtFin}/${estado}/${casa}/${idTema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey18Subtema = async (dtIni: any, dtFin: any, estado: string, casa: number, idSubtema: number): Promise<any> => {
        const result = await http.get(`/insights18Subtema/${dtIni}/${dtFin}/${estado}/${casa}/${idSubtema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey19List = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights19/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {

            return result
        }
        return undefined;
    }

    GetKey19Grafico = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights19Grafico/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey23_24Grupo = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights23_24/grupos/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey23_24Tema = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights23_24/temas/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }


    GetKey23_24Subtema = async (dtIni: any, dtFin: any, esfera: number): Promise<any> => {
        const result = await http.get(`/insights23_24/subtemas/${dtIni}/${dtFin}/${esfera}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }


    GetKey25_26Grupo = async (dtIni: any, dtFin: any, idGrupo: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights25_26Grupo/${dtIni}/${dtFin}/${idGrupo}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey21List = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights21/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {

            return result
        }
        return undefined;
    }

    GetKey21Grafico = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights21Grafico/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey20List = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights20/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey20Grafico = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights20Grafico/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey22List = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights22/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey22Grafico = async (dtIni: any, dtFin: any, casa: number, posicionamento: number): Promise<any> => {
        const result = await http.get(`/insights22Grafico/${dtIni}/${dtFin}/${casa}/${posicionamento}`);
        if (result.success) {
            return result

        }
        return undefined;
    }


    GetKey25_26Tema = async (dtIni: any, dtFin: any, idGrupo: number, idTema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights25_26Tema/${dtIni}/${dtFin}/${idGrupo}/${idTema}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey25_26Subtema = async (dtIni: any, dtFin: any, idGrupo: number, idTema: number, idSubtema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights25_26SubTema/${dtIni}/${dtFin}/${idGrupo}/${idTema}/${idSubtema}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }


    GetKey27_28Grupo = async (dtIni: any, dtFin: any, idGrupo: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights27_28/grupos/${dtIni}/${dtFin}/${idGrupo}/${esfera}`);
        if (result.success) {
            return result

        }
        return undefined;
    }

    GetKey27_28Tema = async (dtIni: any, dtFin: any, idTema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights27_28/temas/${dtIni}/${dtFin}/${idTema}/${esfera}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey27_28Subtema = async (dtIni: any, dtFin: any, idSubtema: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights27_28/subtemas/${dtIni}/${dtFin}/${idSubtema}/${esfera}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey29AutoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/autoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey29AutoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/autoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey29AutoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/autoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey29RelatoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/relatoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey29RelatoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/relatoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }


    GetKey29RelatoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights29/relatoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey30Grupo = async (dtIni: any, dtFin: any, idGrupo: number): Promise<any> => {
        const result = await http.get(`/insights30/grupos/${dtIni}/${dtFin}/${idGrupo}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey30Tema = async (dtIni: any, dtFin: any, idTema: number): Promise<any> => {
        const result = await http.get(`/insights30/temas/${dtIni}/${dtFin}/${idTema}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey30Subtema = async (dtIni: any, dtFin: any, idSubtema: number): Promise<any> => {
        const result = await http.get(`/insights30/subtemas/${dtIni}/${dtFin}/${idSubtema}`);
        if (result.success) {

            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey31Grupo = async (dtIni: any, dtFin: any, idGrupo: number): Promise<any> => {
        const result = await http.get(`/insights31/grupos/${dtIni}/${dtFin}/${idGrupo}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey31Tema = async (dtIni: any, dtFin: any, idTema: number): Promise<any> => {
        const result = await http.get(`/insights31/temas/${dtIni}/${dtFin}/${idTema}`);
        if (result.success) {
            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey31Subtema = async (dtIni: any, dtFin: any, idSubtema: number): Promise<any> => {
        const result = await http.get(`/insights31/subtemas/${dtIni}/${dtFin}/${idSubtema}`);
        if (result.success) {

            return {

                success: true,
                data: result
            }
        }
        return undefined;
    }

    GetKey32AutoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/autoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {


            return result

        }
        return undefined;
    }

    GetKey32AutoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/autoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey32AutoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/autoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey32RelatoriasGrupo = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/relatoriasGrupo/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey32RelatoriasTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/relatoriasTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey32RelatoriasSubTema = async (dtIni: any, dtFin: any, idParlamentar: number): Promise<any> => {
        const result = await http.get(`/insights32/relatoriasSubTema/${dtIni}/${dtFin}/${idParlamentar}`);
        if (result.success) {

            return result

        }
        return undefined;
    }

    GetKey33CasaGrupo = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33Grupo/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey33CasaTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33Tema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey33CasaSubTema = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33SubTema/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey33CasaGrupoGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33GrupoGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey33CasaTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33TemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }
    GetKey33CasaSubTemaGrafico = async (dtIni: any, dtFin: any, casa: number, esfera: number): Promise<any> => {
        const result = await http.get(`/insights33SubTemaGrafico/${dtIni}/${dtFin}/${casa}/${esfera}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey34Grupo = async (dtIni: any, dtFin: any, siglaCasa: string, idGrupo: number): Promise<any> => {
        const result = await http.get(`/insights34/grupo/${dtIni}/${dtFin}/${siglaCasa}/${idGrupo}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey34Tema = async (dtIni: any, dtFin: any, siglaCasa: string, idTema: number): Promise<any> => {
        const result = await http.get(`/insights34/tema/${dtIni}/${dtFin}/${siglaCasa}/${idTema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

    GetKey34Subtema = async (dtIni: any, dtFin: any, siglaCasa: string, idSubtema: number): Promise<any> => {
        const result = await http.get(`/insights34/subtema/${dtIni}/${dtFin}/${siglaCasa}/${idSubtema}`);
        if (result.success) {
            return result
        }
        return undefined;
    }

}

export default new InsightService();



