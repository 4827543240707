import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Form } from '@unform/web';
import { TextField } from 'unform-material-ui';
import { FormHandles } from '@unform/core'
import { Button, Grid, Box, CircularProgress, CssBaseline, Paper, InputAdornment, IconButton, Alert, Stack, Link } from '@mui/material';
import Copyright from '../../components/Copyright';
import { useAuthorization } from '../../contexts/AuthorizationContext';
import { usePallet } from '../../contexts/PalletContext';
import logo from '../../img/logo-legisnote-roxo.svg';
import backgroundImage from "../../img/imagem_login.png";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import CircularLoading from '../../components/CircularLoading';
import { useAlert } from '../../contexts/AlertContext';
import AuthorizationContextService from '../../services/AuthorizationContextService';
import TrocaSenhaModal from './TrocaSenhaModal';


const spaceWidthRegionForm = isMobile ? 6 : 10

const styleLogin = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    imageList: {
        width: "auto",
        height: "auto",
    },
    image: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(spaceWidthRegionForm),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const { login, loading, isAutenticated } = useAuthorization();
    const classes = styleLogin();
    const formRef = React.useRef<FormHandles>(null);
    const [displaySpinner, setDisplaySpinner] = React.useState<boolean>(false);
    const [displaySpinnerEmail, setDisplaySpinnerEmail] = React.useState<boolean>(false);
    const [emailHelperText, setEmailHelperText] = React.useState<string>("");
    const [passwordHelperText, setPasswordHelperText] = React.useState<string>("");
    const [referenceClient, setReferenceClient] = React.useState("");
    const [msgErroNoClient, setMsgErroNoCLient] = React.useState("");
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined);
    };


    type handleSubmitData = { email: String, password: String, remember: boolean }

    const ButtonLoginSSO = () => {
        redirecionarSsoCliente();
        return (<Alert>Autenticando via solução integrada do cliente...</Alert>);
    }

    const redirecionarSsoCliente = () => {
        let splitEmail = email.split("@");
        let capClient = splitEmail[1].split('.');
        const hostname = window.location.hostname;
        if (capClient && capClient[0]) {
            let refClient = capClient[0];
            if (hostname != 'localhost' && (
                (refClient == 'bradesco' && hostname != 'bradesco.legisnote.com.br')
                || (refClient == 'corpp' && capClient[1] === 'bradesco' && hostname != 'dev.legisnote.com.br')
                || ((refClient == 'cielo' || refClient == 'prestadorcbmp') && hostname != 'cielo.legisnote.com.br')
                || (refClient == 'itau-unibanco' && hostname != 'dev.legisnote.com.br' && hostname != 'itau.legisnote.com.br')
            )) {
                setReferenceClient('noSso');
            } else {
                setReferenceClient(refClient);
            }
        }
        if (referenceClient && referenceClient === 'corpp' && capClient[1] === 'bradesco') {
            window.location.replace('https://idhibrida.prebanco.com.br/adfs/ls/idpinitiatedsignon.aspx?LoginToRP=https://dev.legisnote.com.br/api/login/callback')
        }
        if (referenceClient && referenceClient === 'bradesco') {
            window.location.replace('https://sts.bradesco.com.br/adfs/ls/idpinitiatedsignon.aspx?LoginToRP=https://bradesco.legisnote.com.br/api/login/callback')
        }
        if (referenceClient && (referenceClient === 'cielo' || referenceClient === 'prestadorcbmp')) {
            window.location.replace('https://cielo.legisnote.com.br/api/mslogin')
        }
        if (referenceClient && referenceClient === 'itau-unibanco') {
            window.location.replace(`https://${hostname}/api/mslogin-itau`)
        }
    }

    const handleClickCancelar = () =>{

        setReferenceClient("")
        setDisplaySpinnerEmail(false);
        onBlurSenha();
        onFocus();

    }

    const handleFlowClient = async ({ email, password, remember }: handleSubmitData, { reset }: any) => {

        await AuthorizationContextService.GetPassEmail(email)
            .then(res => {
                if (res) {
                    setDisplaySpinnerEmail(true);
                    onFocus();
                    onBlur();
                    redirecionarSsoCliente();
                } else {
                    setMsgErroNoCLient("Email não identificado como usuário.")
                }
            });
    }

    function handleSubmit({ email, password, remember }: handleSubmitData, { reset }: any) {
      
        const schema = yup.object().shape({
            email: yup.string().email('Email inválido').required('Email é obrigatório'),
            // password: yup.string().min(6, 'Senha: Mínimo de 6 caracteres'), // Validação desativada em função de já existirem senhas com menos de 6 caracters.
            password: yup.string().required('Senha obrigatória'),
        });

        schema.validate({ email, password }, { abortEarly: false })
            .then(async x => {
                setDisplaySpinner(true);
                const retorno = await login(email, password).then(success => {
                }).catch(error => {
                    console.log('*** acr4', error);
                    setPasswordHelperText(error);
                    throw { message: '2 errors occurred' };
                });
                console.log('*** acr5', retorno);
            })
            .catch(err => {
                console.log('*** acr2', err);
                if ((err?.message || '').indexOf('Email') >= 0) {
                    setEmailHelperText(err.message);
                } else {
                    setPasswordHelperText(err.message);
                }
            });
    }

    const { pallet } = usePallet();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [focused, setFocused] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const [email, setEmail] = useState('');
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const [focusedSenha, setFocusedSenha] = React.useState(false);
    const onFocusSenha = () => setFocusedSenha(true);
        


    const onBlurSenha = () =>  setFocusedSenha(false);  
    const [senha, setSenha] = useState('');
    const [changePassword, setChangePassword] = useState(false);
    const [noChangePassword, setNoChangePassword] = useState(false);
    const handleSenhaChange = (event: any) => {
        setSenha(event.target.value);
    };

    const statusSenha = (info) => {
        if (info) {
            setChangePassword(true)
            setTimeout(() => {
                setChangePassword(false)
            }, 5000);
        }; 
    };

    return (
        isAutenticated() || loading ?
            <CircularLoading
                size={80}
                top={"50%"}
                left={"50%"}
                zIndex={2}
            /> :
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                {changePassword ? 
                    <Alert style={{position: 'absolute', top: 1, left: '43%'}} severity="success">Senha alterada com sucesso.</Alert>
                    : null}
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                {/* <Grid item xs={false} sm={4} md={7}>
                    <ImageList rowHeight={160} className={classes.imageList} cols={3}>
                        {itemDataImag.map((item) => (
                            <ImageListItem key={item.img} cols={item.cols || 1}>
                                <img src={item.img} alt={item.title} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid> */}
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper} >
                        {isMobile ? <img src={logo} alt="Logo" width="250px" style={{ marginBottom: "40px" }} /> : null}
                        <Form ref={formRef} className={classes.form} noValidate onSubmit={
                            referenceClient && referenceClient !== 'corpp' && referenceClient !== 'bradesco' && referenceClient !== 'cielo' && referenceClient !== 'prestadorcbmp' && referenceClient !== 'itau-unibanco' ? handleSubmit : handleFlowClient}>
                            <TextField
                                autoFocus
                                disabled={displaySpinnerEmail}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                onChange={handleEmailChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                helperText={emailHelperText}
                                //autoComplete='email'
                                InputLabelProps={{ shrink: focused === true || email !== '' ? true : false }}
                            //inputProps={{form:{autocomplete: "off"}}}

                            />
                            {msgErroNoClient && msgErroNoClient !== ""
                                ?
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: '-3px', marginBottom: '10px' }}>
                                    {msgErroNoClient}
                                </Grid>
                                :
                                null
                            }
                            {referenceClient && referenceClient !== "corpp" && referenceClient !== "bradesco" && referenceClient !== "cielo" && referenceClient !== "prestadorcbmp" && referenceClient !== "itau-unibanco" ?
                                <TextField
                                    disabled={displaySpinner}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Senha"
                                    value={senha}
                                    type={showPassword ? "text" : "password"}
                                    helperText={passwordHelperText}
                                    onAnimationEnd={handleSenhaChange}
                                    onChange={handleSenhaChange}
                                    onFocus={onFocusSenha}
                                    onBlur={onBlurSenha}
                                    InputLabelProps={{ shrink: focusedSenha === true || senha !== '' ? true : false }}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                :
                                null}

                            {displaySpinner
                                ?
                                <Grid container justifyContent="center">
                                    <CircularProgress />
                                </Grid>
                                :
                                <div>
                                    {referenceClient && (referenceClient === 'corpp' || referenceClient === 'bradesco' || referenceClient == 'cielo' || referenceClient == 'prestadorcbmp' || referenceClient == 'itau-unibanco')
                                        ?
                                        <ButtonLoginSSO />
                                        : <Button
                                            size="large"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className={classes.submit}
                                            style={{
                                                backgroundColor: `${pallet.botaoModelo1.pressOn.background}`,
                                                color: `${pallet.botaoModelo1.pressOn.color}`,
                                                fontSize: `${pallet.general.size14}`,
                                                fontWeight: pallet.general.weight_100,
                                                textTransform: "capitalize",
                                                height: "55px"
                                            }}
                                        >
                                            Entrar
                                        </Button>}
                                    {referenceClient ?
                                        <Grid style={{display:"flex",justifyContent:"space-between"}}>
                                        <Grid>
                                            <TrocaSenhaModal
                                                closeMenu={() => handleClose()}
                                                email={email}
                                                statusSenha={statusSenha}
                                            />
                                        </Grid>
                                            <Grid>
                                            <Link onClick={() => handleClickCancelar()} >
                                                <Grid
                                                    style={{ fontSize: "11px", marginTop: '10px', cursor: "pointer" }}>
                                                    Cancelar
                                                </Grid>
                                            </Link>
                                            </Grid>
                                        </Grid>
                                        :
                                        null}



                                    {/* <Grid container>
                                        <Grid item xs>
                                            <Link href="#" variant="body2">
                                                Esqueci minha senha?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="/cadastro" variant="body2">
                                                {"Fazer meu cadastro"}
                                            </Link>
                                        </Grid>
                                    </Grid> */}
                                </div>
                            }
                            <Box mt={8}>
                                <Copyright />
                            </Box>
                        </Form>
                    </div>
                </Grid>
            </Grid>
    );
}
export { SignIn };


