import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import mime from 'mime-types';
import { readStore, writeStore } from "./persistence";
import https from "https";
import cacheConfig from './cache-config';
import { getFromCache, saveToCache } from './cache-helper';

interface IDataResult { success: boolean, status: any, message: String, data?: any }

const defaultHeader = {
    "Content-type": "application/json",
    "Basic": "Legisnote:Leg!$note@2021"
}

const instance: AxiosInstance = axios.create({
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 50000,
    headers: defaultHeader
});

instance.interceptors.request.use(async (config) => {
    const token: string = await readStore('token');
    if (token) config.headers.Authorization = 'Bearer ' + token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, err => {
    return new Promise(async (resolve, reject) => {
        const refreshToken: string = await readStore('refresh-token');
        if (refreshToken && err && err.response && err.response.status && err.response.status === 401 && err.config && !err.config.retry) {
            const oReq = err.config;
            oReq.retry = true;
            instance.put('/refresh', { token: refreshToken })
                .then(async res => {
                    if (res.data.auth.token) {
                        await writeStore('token', res.data.auth.token);
                        await writeStore('refresh-token', res.data.auth.refreshToken);
                        instance(oReq).then(res => resolve(res));
                    }
                }).catch(() => reject(err));
        } else {
            reject(err);
        }
    })
})

function habilitarCaching() {
    const shouldCacheRequest = (config) => {
        const { url, method } = config;
        return cacheConfig.some(item => {
            const endpointMatches = new RegExp(item.endpoint.replace('*', '.*')).test(url);
            const methodMatches = item.method === method?.toUpperCase() || item.method === '*';
            const expirytMatches = item?.expiry > 0;
            return endpointMatches && methodMatches && expirytMatches;
        });
    };
    
    const getExpiryForRequest = (config) => {
        const { url, method } = config;
        const cacheItem = cacheConfig.find(item => {
            const endpointMatches = new RegExp(item.endpoint.replace('*', '.*')).test(url);
            const methodMatches = item.method === method?.toUpperCase() || item.method === '*';
            return endpointMatches && methodMatches;
        });
        return cacheItem ? cacheItem.expiry : null;
    };
    
    instance.interceptors.request.use(async (config) => {
        if (shouldCacheRequest(config)) {
            const cachedResponse = getFromCache(config);
            if (cachedResponse) {
                return Promise.reject({ __isCached: true, data: cachedResponse });
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    instance.interceptors.response.use((config) => {
        if (shouldCacheRequest(config.config)) {
            const expiry = getExpiryForRequest(config.config);
            saveToCache(config.config, config.data, expiry);
        }
        return config;
    }, (error) => {
        if (error.__isCached) {
            return Promise.resolve({ data: error.data });
        }
        return Promise.reject(error);
    });
}

// habilitarCaching();

export async function get(url: string, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.get(url, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function post(url: string, data?: any, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.post(url, data, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function put(url: string, data: any, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.put(url, data, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function del(url: string, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.delete(url, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function options(url: string, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.options(url, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: defaultHeader, ...config }
    return instance.patch(url, data, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

export async function download(url: string, config?: AxiosRequestConfig): Promise<IDataResult> {
    let mimetype = mime.lookup(url.substring(url.length - 6));
    if (!mimetype) mimetype = 'application/octet-stream';
    config = { headers: { ...defaultHeader, 'Content-Type': mimetype }, ...config, responseType: 'blob' };
    return instance.get(url, config)
}

export async function upload(url: string, data: any, config?: AxiosRequestConfig): Promise<IDataResult> {
    config = { headers: { ...defaultHeader, 'Content-Type': 'application/json;charset=UTF-8' }, ...config, }
    return instance.post(url, data, config)
        .then(res => { return { success: true, status: res.status, message: res.statusText, data: res.data } })
        .catch(error => { return { success: false, status: error.response && error.response.status ? error.response.status : 500, message: error.message ? error.message : 'Api Arror', data: error.response && error.response.data ? error.response.data : {} } });
}

