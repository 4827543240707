import ButtonCount from '../../../components/ButtonCount';

import { usePallet } from '../../../contexts/PalletContext';


export interface IFiltroNotificacaoProps {
    key: string,
    label: string,
    total: number,
    active: boolean,
};


export function NotificacoesList(props: {
    filtros: IFiltroNotificacaoProps[],
    onChange: (event: any) => void,
}): JSX.Element {
    const { pallet } = usePallet();

    return (
        <>
            {props.filtros?.map((item) => {
                return (
                    <>
                        <ButtonCount
                            item={item}
                            active={item.active}
                            onClick={(e) => props.onChange(e)}
                            style={{
                                backgroundColor: `${item.active ? pallet.textColorQuintenary : "#FFFFFF"}`,
                                //flexDirection: "row-reverse",
                                //justifyContent: "space-between"    
                            }}
                        />

                    </>
                )
            })}
        </>
    );
};

export default NotificacoesList;
