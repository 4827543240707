import { useCallback, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';

import styled from "styled-components";

import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Divider, Grid, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Visibility } from "@material-ui/icons";

import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos'
import PaginationSaveState from "../../../../components/PaginationSaveState";
import FilterQuantity from "../../../../components/filter/FilterQuantity";
import { IFilterData } from "../../../../components/filter/ListFilter";
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import CircularLoading from '../../../../components/CircularLoading';
import InputTextField from '../../../../components/InputTextField';
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import DeadLineDate from '../../../../components/DeadLineDate';
import useStyles from '../../../../components/layout/Styles';
import SolidButton from '../../../../components/SolidButton';
import EmptyError from "../../../../components/EmptyError";

import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { usePallet } from '../../../../contexts/PalletContext';
import { useAlert } from '../../../../contexts/AlertContext';

import AccordionTextDataEmendaProposicao from "./AccordionTextDataEmendaProposicao";
import ProposicaoMenuFiltroEmendas from "./ProposicaoMenuFiltroEmendas";

import ProposicaoEmendaCreateSchema from '../../models/ProposicaoEmendaCreateSchema';
import { IListInfo } from "../../../../models/IListInfo";

import ProposicaoService from "../../../../services/ProposicaoService";

export interface IFileInfo {
    name: string,
    filename: string,
    size: number,
    new: boolean,
    file: any
};


const PaperContent = styled(Paper, isMobile)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;


export default function ProposicaoEmendas(props: {
    id_proposicao: number,
    index: number,
    activeTab: number,
    proposicao_integrada: boolean
    onClick?: () => void,
    onChange?: () => void,
}) {

    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [filtro, setFiltro] = useState<IFilterData[]>([{ key: 'posicionamento', label: 'Posicionamento', value: 'T' }]);
    const [opfiltro, setOpfiltro] = useState("Tudo");
    const [loading, setLoading] = useState(true);
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    const [idEmenda, setIdEmenda] = useState(0);
    const [dataApresentacao, setDataApresentacao] = useState<string>(Date);
    const [posicionamento, setPosicionamento] = useState('');
    const [numero, setNumero] = useState('');
    const [ementa, setEmenta] = useState('');
    const [error, setError] = useState<Array<{ name: string, err: string }>>([]);
    const [data, setData] = useState({});
    const [loadingForm, setLoadingForm] = useState(false)
    const [expandedAcordion, setExpandedAcordion] = useState(false);
    const { user } = useAuthorization();


    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);

        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        );
    };

    const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; `;

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 64px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }`;

    useEffect(() => {
        if (currentPage > 0)
            getList();
    }, [currentPage, currentPageSize, filtro]);

    const getList = useCallback(() => {
        setLoading(true);

        const filter = filtro.map(item => { return { key: item.key, value: item.value } });

        if (currentPage && currentPage > 0) {
            ProposicaoService.GetListEmenda(props.id_proposicao, filter, currentPage, currentPageSize).then(async res => {
                if (res && res.success) {
                    setLista(res.listData);
                    setInfoList(res.listInfo);
                };
                setLoading(false);
            });
        };
        setLoading(false);
    }, [props.id_proposicao, currentPage, currentPageSize, filtro]);

    const descTipo = (value: string) => {
        switch (value) {
            case "T": return "Tudo";
            case "C": return "Contrário";
            case "E": return "Contrário com Emenda";
            case "N": return "Neutro";
            case "A": return "Favorável com Emenda";
            case "F": return "Favorável";
            case "P": return "Pendente";
            case "I": return "Indefinido";
            default: return "Tudo";
        };
    };

    const setFilter = (f: any) => {
        setOpfiltro(descTipo(f.target.value));
        setFiltro([{ key: f.target.name, label: f.target.name, value: f.target.value }]);
    };

    const handleChangeNovaEmenda = () => {
        getList();
    };

    const corPosicionamento = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        };
    };

    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        )
    };

    const posicionamentosItens = [
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
    ];

    const handleNumeroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 15) {
            setNumero(event.target.value)
        }
    };

    const handleChangeAcordion =
        (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedAcordion(!ativo);
        };

    const handleDataApresentacaoChange = (dataapresentacao) => {
        setDataApresentacao(dataapresentacao)
    };

    const handleEmentaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmenta(event.target.value)
    };

    const handleEmendaPosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosicionamento(event.target.value)
    };

    function handleAfterUpload() {
        setIdEmenda(undefined);
        setNumero("")
        setDataApresentacao(Date)
        setEmenta("")
        setPosicionamento("")
        NewAlert('success', 'Emenda criada com sucesso')
        // props.onClick()
    };

    const salvarNovaEmenda = async () => {
        const data = {
            numero: numero,
            dataApresentacao: dataApresentacao ? new Date(dataApresentacao) : null,
            ementa: ementa,
            posicionamento,
        };

        setError(() => [])
        data && ProposicaoEmendaCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data);

        ProposicaoEmendaCreateSchema.validate(data).catch(function (err) {});

        if (ProposicaoEmendaCreateSchema.isValidSync(data)) {
            await ProposicaoService.CreateEmenda(data, props?.id_proposicao)
                .then(res => {
                    if (res && res > 0) {
                        setIdEmenda(res);
                        setLoadingForm(true);
                        setTimeout(() => {
                            setLoadingForm(false)
                        }, 200);
                    } else {
                        NewAlert('error', 'Verifique os campos e tente outra vez');
                        setTimeout(() => {
                            setLoadingForm(false)
                            // setLoading(false)
                        }, 500);
                    }
                })
                .then(data => {
                    // props?.onChange()
                    handleChangeNovaEmenda()
                    handleAfterUpload()
                    setExpandedAcordion(false)
                })
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
            setTimeout(() => {
                setLoadingForm(false)
                // setLoading(false)
            }, 500);
        };
    };

    const permissaoErro = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.");

    };

    return (
        <div
        // style={{minHeight:'800px'}}
        >
            {
                // Se nao tiver emendas?
                infoList?.totalNoFilter === 0
                    ?
                    <div>
                        {/* duas imagens */}
                        {
                            props?.proposicao_integrada === true
                                ?
                                <Grid style={{ marginTop: isMobile ? "0px" : "-30px" }}>
                                    <PaperContent pallet={pallet}>
                                        <EmptyError title="Ops!" description="Ainda não há emendas para esta proposição." />
                                    </PaperContent>
                                </Grid>
                                
                                :

                                <Grid>
                                    <Grid direction="row" alignItems="baseline" style={{
                                        paddingLeft: `${isMobile ? "30px" : "45px"}`,
                                        paddingRight: `${isMobile ? "30px" : "50px"}`,
                                        paddingTop: `${isMobile ? "10px" : "10px"}`,
                                        paddingBottom: `${isMobile ? "0px" : infoList?.totalItens ? "0px" : "25px"}`,

                                    }} >
                                        {loading === true
                                            ?
                                            <CircularLoading
                                                size={80}
                                                top={"90%"}
                                                left={"50%"}
                                                zIndex={2}

                                            />

                                            :

                                            <Accordion disabled={false} elevation={0} style=
                                                {{
                                                    border: "1px solid #EAEAEA",

                                                }}
                                                onChange={handleChangeAcordion(expandedAcordion)}
                                            >
                                                <AccordionSum
                                                    expandIcon={<ArrowDropDownIcon
                                                        style={{
                                                            color: `${pallet.general.color3}`,
                                                            fontSize: `${pallet.general.size16}`,

                                                        }}
                                                    />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    style={{
                                                        backgroundColor: `${pallet.color.primary.background}`,
                                                        borderRadius: "5px",

                                                    }}
                                                >
                                                    <Typography className={classes.heading} style={{
                                                        color: `${pallet.color.primary.font}`,
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        fontSize: `${pallet.fontSize.titleWidgets}`,
                                                        fontWeight: pallet.fontWeight.subtitle,
                                                        alignItems: "center",
                                                        letterSpacing: "1px",
                                                        marginLeft: "10px",
                                                    }}>
                                                        NOVA EMENDA
                                                    </Typography>
                                                </AccordionSum>
                                                <AccordionDetails style={{
                                                    fontFamily: `${pallet.fontFamily.general}`
                                                }}>

                                                    <Grid >
                                                        <Grid container xl={12} lg={12} xs={12} style={{ paddingTop: "20px" }}>
                                                            <Grid container spacing={3}
                                                                style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                                                            >
                                                                <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>

                                                                    <InputTextField
                                                                        type={"number"}
                                                                        value={numero}
                                                                        name="numero"
                                                                        onChange={handleNumeroChange}
                                                                        id={"numero-emenda"}
                                                                        label={"NÚMERO"}
                                                                    />

                                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                        <ErrorMessage name='numero' errors={error} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}`, }} >
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}
                                                                        style={{
                                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                                            fontWeight: pallet.general.weight_600,
                                                                            color: `${pallet.general.color1}`,
                                                                        }}>
                                                                        <DeadLineDate
                                                                            onChange={handleDataApresentacaoChange}
                                                                            label={"DATA DE APRESENTAÇÃO"}
                                                                            inputFormat={"dd/MM/yyyy"}
                                                                            // defaultValue={prazo}
                                                                            value={dataApresentacao}
                                                                            id={"data-apresentacao"}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Grid>
                                                                <Grid item lg={12} xs={12}>
                                                                    <InputMultilineTextField
                                                                        type={"text"}
                                                                        // focused={true}
                                                                        placeholder={"Digite"}
                                                                        maxRows={10}
                                                                        value={ementa}
                                                                        defaultValue={ementa}
                                                                        onChange={handleEmentaChange}
                                                                        id={"descricao-ementa"}
                                                                        label="EMENTA"
                                                                    />
                                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                        <ErrorMessage name='ementa' errors={error} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} xs={12}>
                                                                    <Box component="form">
                                                                        <SelectTextFieldA
                                                                            value={posicionamento}
                                                                            onChange={(e) => handleEmendaPosicionamentoChange(e)}
                                                                            options={posicionamentosItens}
                                                                            id={"posicionamento_ementa"}
                                                                            label={"POSICIONAMENTO"}

                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                    </Box>
                                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                        <ErrorMessage name='posicionamento' errors={error} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid xl={2} lg={2} xs={12} container style={{
                                                                    paddingLeft: `${isMobile ? "25px" : "30px"}`
                                                                }}
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid>
                                                            < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                                        </Grid>

                                                        <Grid style={{
                                                            //paddingTop: `${isMobile ? "15px" : "20px"}`,
                                                            paddingLeft: `${isMobile ? "0px" : "24px"}`,
                                                            paddingRight: `${isMobile ? "0px" : "24px"}`,
                                                        }}>
                                                            {user.id_permissao !== 3 ?
                                                                <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                                    <Grid style={{
                                                                        fontWeight: pallet.fontWeight.subtitle,
                                                                        fontSize: `${pallet.general.size12}`,
                                                                        color: `${pallet.color.tertiary.font}`,

                                                                    }}>
                                                                        ANEXOS
                                                                    </Grid>
                                                                    <Grid style={{ marginTop: "10px" }}>
                                                                        <Grid className="container">

                                                                            <AnexosComponent
                                                                                label={''}
                                                                                modulo={EAnexoModulos.ProposicaoEmenda}
                                                                                id_referencia={idEmenda}
                                                                                onAfterUpload={handleAfterUpload}
                                                                                canEdit={true}
                                                                                autosave={true}
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Grid> : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                                <Grid>
                                                    < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                                                </Grid>
                                                <Grid container alignItems="center" sx={{
                                                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                                    height: `${isMobile ? "50px" : "98px"}`,
                                                    borderRadius: "5px"
                                                }}>
                                                    <Grid container item>

                                                        <Grid item lg={12} xs={12}
                                                            style=
                                                            {{
                                                                paddingRight: `${isMobile ? "0px" : "40px"}`,
                                                                textAlignLast: "end",
                                                                padding: `${isMobile ? "0px" : "10px 20px 10px 20px"}`,
                                                            }}>
                                                            <SolidButton
                                                                onClick={user.id_permissao !== 3 ? salvarNovaEmenda : permissaoErro}
                                                                // disabled={lockSaveButton}
                                                                // onChange={props?.reloadList}
                                                                color={`${pallet.textColorPrimary}`}
                                                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                                                fontSize={`${pallet.general.size14}`}
                                                                title={"SALVAR"}
                                                            // ativo={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Accordion >}
                                    </Grid>

                                    {infoList?.totalItens === undefined && !expandedAcordion || !expandedAcordion && (infoList?.totalItens <= 0 && infoList?.totalNoFilter === 0) &&
                                        <Grid
                                            style={{
                                                color: `${pallet.textColorQuaternary}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: 600,
                                                textAlign: "center",
                                                paddingBottom: "100px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <EmptyErrorDoc title="Comece criando uma nova Emenda"
                                                description="Você ainda não criou nenhuma Emenda para esta proposição."
                                                subdescription=" Clique no botão abaixo para registrar." />
                                        </Grid>}
                                </Grid>
                        }
                    </div>

                    :

                    <div>
                        {/* lista de Emendas */}
                        {props?.proposicao_integrada === false ?
                            loading === true
                                ?
                                <CircularLoading
                                    size={80}
                                    top={"90%"}
                                    left={"50%"}
                                    zIndex={2}

                                />

                                :
                                <Grid>
                                    <Grid direction="row" alignItems="baseline" style={{
                                        paddingLeft: `${isMobile ? "20px" : "45px"}`,
                                        paddingRight: `${isMobile ? "20px" : "50px"}`,
                                        paddingTop: `${isMobile ? "10px" : "10px"}`,
                                        // paddingBottom: `${isMobile ? "0px" : "50px"}`,

                                    }} >
                                        <Accordion disabled={false} elevation={0} style=
                                            {{
                                                border: "1px solid #EAEAEA",

                                            }}
                                            onChange={handleChangeAcordion(expandedAcordion)}
                                        >
                                            <AccordionSum
                                                expandIcon={<ArrowDropDownIcon
                                                    style={{
                                                        color: `${pallet.general.color3}`,
                                                        fontSize: `${pallet.general.size16}`,

                                                    }}
                                                />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    backgroundColor: `${pallet.color.primary.background}`,
                                                    borderRadius: "5px",

                                                }}
                                            >
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.primary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    alignItems: "center",
                                                    letterSpacing: "1px",
                                                    marginLeft: "10px",
                                                }}>
                                                    NOVA EMENDA
                                                </Typography>
                                            </AccordionSum>
                                            <AccordionDetails style={{
                                                fontFamily: `${pallet.fontFamily.general}`
                                            }}>

                                                <Grid >
                                                    <Grid container xl={12} lg={12} xs={12} style={{ paddingTop: "20px" }}>
                                                        <Grid container spacing={3}
                                                            style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                                                        >
                                                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>


                                                                <InputTextField
                                                                    type={"number"}
                                                                    value={numero}
                                                                    name="numero"
                                                                    onChange={handleNumeroChange}
                                                                    id={"numero-emenda"}
                                                                    label={"NÚMERO"}
                                                                />

                                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                    <ErrorMessage name='numero' errors={error} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}`, }} >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                                                    style={{
                                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                                        fontWeight: pallet.general.weight_600,
                                                                        color: `${pallet.general.color1}`,
                                                                    }}>
                                                                    <DeadLineDate
                                                                        onChange={handleDataApresentacaoChange}
                                                                        label={"DATA DE APRESENTAÇÃO"}
                                                                        inputFormat={"dd/MM/yyyy"}
                                                                        // defaultValue={prazo}
                                                                        value={dataApresentacao}
                                                                        id={"data-apresentacao"}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                <InputMultilineTextField
                                                                    type={"text"}
                                                                    // focused={true}
                                                                    placeholder={"Digite"}
                                                                    maxRows={10}
                                                                    value={ementa}
                                                                    defaultValue={ementa}
                                                                    onChange={handleEmentaChange}
                                                                    id={"descricao-ementa"}
                                                                    label="EMENTA"
                                                                />
                                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                    <ErrorMessage name='ementa' errors={error} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                <Box component="form">
                                                                    <SelectTextFieldA
                                                                        value={posicionamento}
                                                                        onChange={(e) => handleEmendaPosicionamentoChange(e)}
                                                                        options={posicionamentosItens}
                                                                        id={"posicionamento_ementa"}
                                                                        label={"POSICIONAMENTO"}

                                                                    />
                                                                </Box>
                                                                <Box>
                                                                </Box>
                                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                                    <ErrorMessage name='posicionamento' errors={error} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid xl={2} lg={2} xs={12} container style={{
                                                                paddingLeft: `${isMobile ? "25px" : "30px"}`
                                                            }}
                                                                justifyContent="flex-start"
                                                                alignItems="center">
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid>
                                                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                                    </Grid>

                                                    <Grid style={{
                                                        //paddingTop: `${isMobile ? "15px" : "20px"}`,
                                                        paddingLeft: `${isMobile ? "0px" : "24px"}`,
                                                        paddingRight: `${isMobile ? "0px" : "24px"}`,
                                                    }}>

                                                        {user.id_permissao !== 3 ?
                                                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                                <Grid style={{
                                                                    fontWeight: pallet.fontWeight.subtitle,
                                                                    fontSize: `${pallet.general.size12}`,
                                                                    color: `${pallet.color.tertiary.font}`,

                                                                }}>
                                                                    ANEXOS
                                                                </Grid>
                                                                <Grid style={{ marginTop: "10px" }}>
                                                                    <Grid className="container">

                                                                        <AnexosComponent
                                                                            label={''}
                                                                            modulo={EAnexoModulos.ProposicaoEmenda}
                                                                            id_referencia={idEmenda}
                                                                            onAfterUpload={handleAfterUpload}
                                                                            canEdit={true}
                                                                            autosave={true}
                                                                        />
                                                                    </Grid>

                                                                </Grid>

                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                            <Grid>
                                                < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                                            </Grid>
                                            <Grid container alignItems="center" sx={{
                                                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                                height: `${isMobile ? "50px" : "98px"}`,
                                                borderRadius: "5px"
                                            }}>
                                                <Grid container item>

                                                    <Grid item lg={12} xs={12}
                                                        style=
                                                        {{
                                                            paddingRight: `${isMobile ? "0px" : "40px"}`,
                                                            textAlignLast: "end",
                                                            padding: `${isMobile ? "0px" : "10px 20px 10px 20px"}`,
                                                        }}>
                                                        <SolidButton
                                                            onClick={user.id_permissao !== 3 ? salvarNovaEmenda : permissaoErro}
                                                            // disabled={lockSaveButton}
                                                            // onChange={props?.reloadList}
                                                            color={`${pallet.textColorPrimary}`}
                                                            backgroundColor={`${pallet.backgroundColorPrimary}`}
                                                            fontSize={`${pallet.general.size14}`}
                                                            title={"SALVAR"}
                                                        // ativo={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Accordion >
                                    </Grid>
                                </Grid>
                            : <Grid></Grid>

                        }
                        {infoList?.totalNoFilter > 0

                            ?

                            <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                <Grid item xl={12} lg={12} xs={12} >
                                    <Grid container item alignItems="flex-start" style={{
                                        paddingLeft: `${isMobile ? "10px" : "30px"}`,
                                        paddingRight: `${isMobile ? "10px" : "30px"}`,
                                        paddingTop: `${isMobile ? "10px" : props.proposicao_integrada ? "0px" : "30px"}`,
                                        marginBottom: `${isMobile ? "-10px" : "10px"}`
                                    }} >
                                        <Grid container item alignItems="baseline" xs={12} >
                                            <Grid xs={6} container item alignItems="baseline">
                                                <Grid item style={{
                                                    marginRight: `${isMobile ? "8px" : "8px"}`,
                                                    paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                                }}>
                                                    <Grid item style={{
                                                        fontSize: "18px",
                                                        color: `${pallet.textColorTertiary}`,
                                                        fontWeight: pallet.general.weight_600
                                                    }}>
                                                        {infoList?.totalItens}
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                    <Grid item style={{
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        fontSize: "14px",
                                                        fontWeight: pallet.general.weight_600,
                                                        paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                    }}>
                                                        {infoList?.totalItens > 1 ? "EMENDAS" : "EMENDA"} [ {opfiltro} ]
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid xs={6} container item justifyContent="flex-end" >
                                                <Grid item>
                                                    <Grid style={{ paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}`, paddingLeft: `${isMobile ? "0" : "0px"}` }}>
                                                        <ProposicaoMenuFiltroEmendas title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {infoList?.totalItens > 4 ?
                                                <Grid item xs={12} style={{ marginTop: "5px", marginBottom: "20px", paddingLeft: `${isMobile ? "10px" : "20px"}` }}>
                                                    <FilterQuantity
                                                        list={infoList}
                                                        currentPageSize={currentPageSize}
                                                        onChange={handleOnChangeItensPerPageSize}
                                                    />
                                                </Grid>
                                                : null}
                                        </Grid>
                                        {loadingAfterSalvar === true

                                            ?

                                            <CircularLoading
                                                size={80}
                                                top={"90%"}
                                                left={"50%"}
                                                zIndex={2}

                                            />
                                            :
                                            <Grid style={{
                                                width: "100%",
                                                paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                                paddingRight: `${isMobile ? "20px" : "20px"}`,
                                                paddingTop: "20px",
                                                paddingBottom: `${isMobile ? "20px" : "40px"}`
                                            }}
                                            >
                                                <AccordionTextDataEmendaProposicao
                                                    lista={lista}
                                                    onChange={handleChangeNovaEmenda}
                                                    proposicao_integrada={props.proposicao_integrada}
                                                />
                                            </Grid>
                                        }

                                    </Grid>
                                    <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                        <PaginationSaveState
                                            name={'ParlamentarEmendaList'}
                                            pages={infoList?.totalPages}
                                            page={currentPage}
                                            onChange={setCurrentPage}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </div>
            }
        </div>
    )
}