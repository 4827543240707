import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { IProposicao } from '../../../../models/Proposicao.Model';
import TabelasService from '../../../../services/TabelasService';
import SelectTextField from '../../../../components/SelectTextField';
import ProposicaoService from '../../../../services/ProposicaoService';
import { useAlert } from '../../../../contexts/AlertContext';



export const ProposicaoConsultaClassificacaoDeTema = (props: {
    proposicao: IProposicao;
    onChange: () => void;
    idPermissao: number;
}) => {

    const { pallet } = usePallet();
    const classes = useStyles();
    const [grupoItens, setGrupoItens] = React.useState([]);
    const [temaItens, setTemaItens] = React.useState([]);
    const [subTemaItens, setSubTemaItens] = React.useState([]);
    const [topicoItens, setTopicoItens] = React.useState([]);
    const { NewAlert } = useAlert();
    const [grupo, setGrupo] = React.useState<string>(undefined);
    const [tema, setTema] = React.useState<string>(undefined);
    const [subtema, setSubtema] = React.useState<string>(props?.proposicao?.cliente?.tema_corporativo ? String(props?.proposicao?.cliente?.tema_corporativo?.subtema_id) : undefined);
    const [topico, setTopico] = React.useState<string>('');

    // Monta itens do select com o retorno da query
    const getItens = (data: { id: number, descricao: string }[]): { value: string, label: string }[] => {
        const ret = data?.map((item) => {
            return {
                "value": String(item.id),
                "label": item.descricao
            }
        });
        return ret;
    }



    React.useEffect(() => {
        setTimeout(() => {
            TabelasService.getProposicaoGrupoTemas().then(
                res => {
                    const data = getItens(res);
                    setGrupoItens(data);
                }
            )
        }, 1000);

    }, []);

    React.useEffect(() => {

        if (grupo) {
            TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then(
                res => {
                    const data = getItens(res)
                    setTemaItens(data);
                }
            )
        }

    }, [grupo]);


    React.useEffect(() => {

        if (tema) {
            TabelasService.getProposicaoSubTemasByTema(Number(tema)).then(
                res => {
                    const data = getItens(res)
                    setSubTemaItens(data);
                }
            )
        }

    }, [tema]);

    React.useEffect(() => {

        if (subtema) {
            TabelasService.getProposicaoTopicosBySubtema(Number(subtema)).then(
                res => {
                    const data = getItens(res)
                    setTopicoItens(data);
                }
            )
        }

    }, [subtema]);

    React.useEffect(() => {
        if (props?.proposicao?.cliente.tema_corporativo?.subtema_id) {
            setTimeout(() => {
                TabelasService.getProposicaoTemasGeral(props?.proposicao?.cliente?.tema_corporativo?.tema_id).then(
                    res => {
                        if (res) {
                            setGrupo(String(res[0]?.id_grupo))
                            setTema(String(props?.proposicao?.cliente?.tema_corporativo?.tema_id));
                        }
                    }
                )
            }, 1000)
        }
    }, [props.proposicao?.cliente?.tema_corporativo?.subtema_id])

    React.useEffect(() => {
        setTimeout(() => {
            TabelasService.getProposicaoTemasTopico(props.proposicao.id).then(res => {
                if (res && res[0] && res[0].id_topico) {
                    setTopico(String(res[0].id_topico))
                }
            }
            )
        }, 1000);
    }, [])

    const onChangeGrupo = (value: string) => {
        if (value) {
            setGrupo(value);
        }
        setTopico(null);
        setSubtema(null);
        setTema(null);
        setTopicoItens([]);
        setSubTemaItens([]);
        setTemaItens([]);
    };

    const onChangeTema = (value: string) => {
        if (value) setTema(value);

        setTopico(null);
        setSubtema(null);
        setTopicoItens([]);
        setSubTemaItens([]);
    };

    const onChangeSubtema = (value: string) => {
        if (value) {
            setSubtema(value);
            ProposicaoService.SetSubtema(props.proposicao.id, Number(value)).then(
                res => {
                    NewAlert('success', 'Classificação por subtema com sucesso.');
                    props.onChange()
                }
            )
        }

        setTopico(null);
        setTopicoItens([]);
    };

    const onChangeTopico = (value: string) => {
        if (value) {
            setTopico(value);
            ProposicaoService.SetTopico(props.proposicao.id, Number(value), Number(subtema)).then(
                res => {
                    NewAlert('success', 'Classificação por tópico com sucesso.');
                    props.onChange()
                }
            )
        }
    };

    const permissaoErro = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")

    }


    return (
        <Grid style={{
            paddingLeft: `${isMobile ? "10px" : "50px"}`,
            paddingRight: `${isMobile ? "10px" : "50px"}`,
            paddingTop: "10px",
            paddingBottom: "10px"
        }}>
            <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: `${isMobile ? "5px" : "20px"}`,
                        paddingRight: `${isMobile ? "5px" : "20px"}`,
                        flexFlow: "row-reverse",
                    }}
                >
                    <Typography className={classes.heading} style={{
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size14}`,
                        fontWeight: pallet.general.weight_600,
                        marginLeft: "10px",
                    }}>
                        <Grid style={{ display: "flex" }}>CLASSIFICAÇÃO DE TEMA CORPORATIVO</Grid>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    fontFamily: `${pallet.general.fontFamily}`
                }}>
                    <Grid container style={{ width: "100%" }}>

                        <Grid item lg={12} xs={12}
                            style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                                marginBottom: "15px",
                                marginRight: "20px"
                            }}>

                            <SelectTextField
                                name="grupo-select"
                                label="GRUPO"
                                style={{ width: "100%" }}
                                itens={grupoItens}
                                value={grupo}
                                onChange={props.idPermissao !== 3 ? onChangeGrupo : permissaoErro}
                            />
                        </Grid>
                        {grupo && temaItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="tema-select"
                                    label="TEMA"
                                    style={{ width: "100%" }}
                                    itens={temaItens}
                                    value={tema}
                                    onChange={props.idPermissao !== 3 ? onChangeTema : permissaoErro}
                                />
                                {!tema ?
                                    <Grid style={{ color: 'red', fontSize: '10px' }}>
                                        Obrigatória a escolha do Tema
                                    </Grid>
                                    : null}
                            </Grid>
                            :
                            null
                        }
                        {tema && subTemaItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="subtema-select"
                                    label="SUBTEMA"
                                    style={{ width: "100%" }}
                                    itens={subTemaItens}
                                    value={subtema}
                                    onChange={props.idPermissao !== 3 ? onChangeSubtema : permissaoErro}
                                />
                                {!subtema ?
                                    <Grid style={{ color: 'red', fontSize: '10px' }}>
                                        Obrigatória a escolha de um subtema para salvar!
                                    </Grid>
                                    : null}
                            </Grid>
                            :
                            null
                        }
                        {subtema && topicoItens.length > 0
                            ?
                            <Grid item lg={12} xs={12}
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                    marginBottom: "15px",
                                    marginRight: "20px"
                                }}>
                                <SelectTextField
                                    name="topico-select"
                                    label="TÓPICO"
                                    style={{ width: "100%" }}
                                    itens={topicoItens}
                                    value={topico}
                                    onChange={props.idPermissao !== 3 ? onChangeTopico : permissaoErro}
                                />

                            </Grid>
                            :
                            null
                        }
                    </Grid>



                </AccordionDetails>
            </Accordion>


        </Grid>
    )


}