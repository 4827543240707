import MenuIcon from "@material-ui/icons/Menu";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  AppBar,
  Grid,
  IconButton,
  Select,
  Toolbar,
  Typography
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InputBase from '@mui/material/InputBase';
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from 'react-router-dom';
import Alerta from "../../components/Alerta";
import { useAlert } from "../../contexts/AlertContext";
import { useAuthorization } from "../../contexts/AuthorizationContext";
import { usePallet } from "../../contexts/PalletContext";
import Relogio from "./Relogio";
import useStyles from '../../components/layout/Styles';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import * as http from '../../helpers/http';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const AppBarCustom = (props: {
  classes: any;
  open: boolean;
  handleToggleOpen: () => void;
  openNotificacao: boolean;
  toggleNotificacao: () => void;
}) => {
  const classes = useStyles();
  const { NewAlert } = useAlert();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { user } = useAuthorization();
  const { pallet } = usePallet();
  // const value = state?.['buscaGeralFiltro'];
  const [value, setValue] = React.useState(state?.['buscaGeralFiltro']);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Deslogar = () => {
    localStorage.clear();
    window.location.href = '/';
  }

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/sw.js').then(registration => {
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            setIsSubscribed(true);
          }
        });
        console.log('Service Worker registrado com sucesso:', registration);
      }).catch(error => {
        console.error('Erro ao registrar Service Worker:', error);
      });
    }
  }, []);

  const ativarNotificacoes = async () => {
    const registration = await navigator.serviceWorker.register('/sw.js');

    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: 'BAXS3v5D3CgfC9WKNqucvbhnzFpXj0GiuW2yGVCVpejcfrD8hHUiix-9SMvL63-RS7H1RB0siySpuA8FfYl3oUg',
    };
    
    const subscription = await registration.pushManager.subscribe(subscribeOptions);
    
    await http.post('/subscribe', subscription);

    console.log('Inscrição para push notification realizada com sucesso.');
    setIsSubscribed(true);
  };

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    color: "black",
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const pressBuscaGeral = (event: any) => {
    if (event?.charCode === 13 || event?.key === 'Enter') {
      event.preventDefault();
      // if (event && event.target.value !== '' && event.target.value.split('')[0] !== '.' && event.target.value.split('')[0] !== '#' && event.target.value.split('')[0] !== '?' && event.target.value.split('')[0] !== '/') {
      if (event && event.target.value.split('')[0] !== '.' && event.target.value.split('')[0] !== '#' && event.target.value.split('')[0] !== '?' && event.target.value.split('')[0] !== '/') {
        const textoGrande = encodeURIComponent(event.target.value);
        const wordKey = textoGrande.replace("/", "-");
        if (selectedInput === 'buscaGeral') {
          navigate(`/redirect`, { state: { rota: `/busca-geral/${wordKey ? wordKey : '/---'}`, state: { buscaGeralSelecionada: selectedInput, buscaGeralFiltro: event.target.value } } });
        } else {
          const filtro = extrairInformacoesProposicao(event?.target?.value);
          if (!filtro) {
            NewAlert("error", "Não foi possível identificar tipo ou ano para a busca de proposições.");
            return;
          }

          let filtroStr = '';
          if (filtro.tipo) {
            filtroStr += `{"key":"tipo","label":"TIPO: ${filtro.tipo}","value":"${filtro.tipo}"},`;
          }
          if (filtro.numero) {
            filtroStr += `{"key":"numero","label":"NUMERO: ${filtro.numero}","value":"${filtro.numero}"},`;
          }
          if (filtro.ano) {
            filtroStr += `{"key":"ano","label":"ANO: ${filtro.ano}","value":"${filtro.ano}"},`;
          }
          navigate(`/redirect`, { state: { rota: `/proposicao?filtro=[${filtroStr.slice(0, -1)}]`, state: { buscaGeralSelecionada: selectedInput, buscaGeralFiltro: event.target.value } } });
        }
      }
    }
  };

  const [selectedInput2, setSelectedInput2] = useState(state?.['buscaGeralSelecionada'] || 'buscaProposicoes');
  const [selectedInput, setSelectedInput] = useState(state?.['buscaGeralSelecionada'] || 'buscaGeral');

  const handleSelectChange = (event) => {
    setValue('');
    setSelectedInput(event.target.value);
  };

  function extrairInformacoesProposicao(str) {
    if (!str) return null;
    const regex = /([a-zA-Z]+)(?:\s+|\/)?(?:(\d+))?(?:\s+|\/)?(?:(\d+))?/;
    const [tipo, numero, ano] = str.match(regex);
    return tipo || numero || ano ? {
      tipo: tipo ? tipo.toUpperCase() : null,
      numero: numero ? parseInt(numero) : null,
      ano: ano ? parseInt(ano) : null,
    } : null;
  }

  const SelectedFilter = styled(Select)`
    #dropdown {
        background: #FFFF;
        height: 9px;
        padding: 10px 35px 10px 10px;
        display: flex;
        align-items: center;
        max-width: 210px;
        input, svg {
          display: none;
        }
    }
    `;

  return (
    <AppBar
      position="absolute"
      className={clsx(
        props.classes.appBar,
        props.open && props.classes.appBarShift
      )}
      style={{
        backgroundColor: `${pallet.general.color3}`,

      }}
    >
      <Alerta />
      <div>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem sx={{
            '&:hover': {
              backgroundColor: "white",
            },
            fontWeight: "bold",
            fontFamily: pallet.fontFamily.general,
            fontSize: pallet.general.size14,
            marginBottom: "-11px"
          }} disableRipple>
            {user?.nome}
          </MenuItem>
          <MenuItem sx={{
            '&:hover': {
              backgroundColor: "white",
            },
            fontWeight: "bold",
            fontFamily: pallet.fontFamily.general,
            color: pallet.general.color4,
            fontSize: pallet.general.size14
          }} disableRipple>
            {user?.departamento} . Perfil {user?.acesso}
          </MenuItem>
          {!isSubscribed && <MenuItem sx={{
            '&:hover': {
              backgroundColor: "white",
            },
            fontWeight: "bold",
            fontFamily: pallet.fontFamily.general,
            color: pallet.general.color4,
            fontSize: pallet.general.size14
            }} onClick={ativarNotificacoes} disableRipple>
            Autorizar notificações
          </MenuItem>}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem sx={{
            '&:hover': {
              backgroundColor: "white",
            },
            fontWeight: "bold",
            fontFamily: pallet.fontFamily.general,
            color: pallet.general.color4,
            fontSize: pallet.general.size14
          }} onClick={Deslogar} disableRipple>
            SAIR
          </MenuItem>
        </StyledMenu>
      </div>

      <Toolbar className={props.classes.toolbar}>

        {isMobile
          ?
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={props.handleToggleOpen}
            className={clsx(
              props.classes.menuButton,
              props.open && props.classes.menuButtonHidden
            )}
          >
            {isMobile ? <MenuIcon /> : ""}
          </IconButton>
          :
          null}

        <div
          style={{
            marginLeft: `${isMobile ? "-30px" : "30px"}`,
            color: "black",
            display: "flex",
            width: `${isMobile ? "220px" : "100px"}`,
            fontSize: pallet.general.size14,
            fontFamily: pallet.fontFamily.general,
            fontWeight: "bold"
          }}
        >
          <Search>
            <Grid container>
              <Grid style={{ width: '700px', display: 'flex' }}>
                <TextField
                  // placeholder={`Digite${selectedInput === 'buscaGeral' ? ' + ENTER' : ' a proposição + ENTER'}`}
                  placeholder={`Digite + ENTER`}
                  fullWidth
                  variant="outlined"
                  defaultValue={value}
                  onKeyPress={pressBuscaGeral}
                  style={{ width: isMobile ? '250px' : '400px', display: 'inline-block' }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    },
                    style: {
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_100,
                      background: `${pallet.backgroundColorSecondary}`
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ToggleButtonGroup
                          value={selectedInput}
                          exclusive
                          onChange={handleSelectChange}
                          aria-label="Escolha de busca"
                          size="small"
                        >
                          {/* <ToggleButton value="buscaProposicoes" aria-label="Busca de proposições">
                            Prop.
                          </ToggleButton> */}
                          {/* <ToggleButton value="buscaDiario" aria-label="Busca pelo diário">
                            Diário
                          </ToggleButton> */}
                          <ToggleButton value="buscaGeral" aria-label="Busca geral">
                            Geral
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Search>
        </div>

        {isMobile
          ?
          null
          :
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={props.classes.title}

          ></Typography>
        }

        {isMobile
          ?
          null
          :
          <Grid style={{ display: "flex" }}>
            <AccessTimeIcon
              sx={{ color: "black", marginRight: "10px" }}
              fontSize="small"
            />
            <div
              style={{
                paddingRight: "130px",
                color: "black",
                display: "flex",
                width: "100px",
                fontSize: pallet.general.size14,
                fontFamily: pallet.fontFamily.general,
                fontWeight: "bold"
              }}
            >
              {<Relogio />}
              <div style={{ marginLeft: "5px" }}>(Brasília)</div>
            </div>
          </Grid>
        }

        {/* <IconButton
          sx={{
            color: props.openNotificacao ? pallet.textColorTertiary : "",
            backgroundColor: props.openNotificacao ? "#E0CDF8" : "",
            marginRight: "5px"
          }}
          size="large"
          onClick={props.toggleNotificacao}
        >
          <NotificationsIcon />
        </IconButton> */}

        <Button
          sx={{ fontFamily: pallet.fontFamily.general }}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </Button>

        {/* <Badge badgeContent={newNotifications} sx={{ color: `${pallet.general.color4}` }}>
          <NotificationsIcon />
        </Badge> */}
      </Toolbar>
    </AppBar >
  );
};
