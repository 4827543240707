import { Autocomplete, CircularProgress, Button, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, autocompleteClasses, RadioGroup, FormControlLabel, Radio, FormControl } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Theme, useTheme } from '@mui/material/styles';
import FilterItemPanel from "../../../../components/filter/FilterItemPanel";

import {
    IListFilterInputItem,
    IListFilterRadioItem,
    ListFilterAutocompleteItem,
    ListFilterCheckBoxItem,
    ListFilterRangeDatePickerItem,
    ListFilterInputItem,
    ListFilterRadioItem,
    ListFilterTextItem,
    ListFilterCheckboxItemEsfera,
    ListFilterCheckboxItemEsfera2,
    MultipleListFilterInputItem,
    IListFilterInputItemNew,
    MultipleListFilterInputItemMunicipio
} from "../../../../components/filter/ListFilterItems";
import TabelasService from "../../../../services/TabelasService";
import { IFilterData } from "../../../../components/filter/ListFilter";
import { useEffect } from "react";
import ProposicaoService from "../../../../services/ProposicaoService";
import { formatDate } from "../../../../utils/dateUtils";
import { usePallet } from '../../../../contexts/PalletContext';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';
import { isNull, size } from "lodash";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Font } from "@react-pdf/renderer";







const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const esferaItens: IListFilterRadioItem[] = [
    { label: "Federal", value: "Federal" },
    { label: "Estadual", value: "Estadual" },
    { label: "Municipal", value: "Municipal" },
];

const casaItens: IListFilterInputItem[] = [
    { key: "casa", label: "Senado Federal", value: "SF" },
    { key: "casa", label: "Congresso Nacional", value: "CN" },
    { key: "casa", label: "Câmara dos Deputados", value: "CD" }
];

const tramitacaoItens: IListFilterInputItem[] = [
    // { key: "todas", label: "Todas", value: "todas" },
    { key: "emTramitacao", label: "Tramitando", value: "tramitando" },
    { key: "emTramitacao", label: "Não Tramitando", value: "naoTramitando" },
    { key: "emTramitacao", label: "Perda de eficácia", value: "perdaEficacia" }
];

const visualizacaoItens: IListFilterInputItem[] = [
    { key: "visualizacao", label: "Seguidas", value: "Seguidas" },
    { key: "visualizacao", label: "Não Seguidas", value: "NSeguidas" },
    { key: "visualizacao", label: "Novas", value: "Novas" },
    { key: "visualizacao", label: "Atualização Recente", value: "Recente" }
];

const momentoItens: IListFilterInputItem[] = [
    { key: "momento", label: "Aguardando Designação do Relator", value: "ADR" },
    { key: "momento", label: "Aguardando Parecer do Relator", value: "APR" },
    { key: "momento", label: "Prazo para Emendas", value: "PE" },
    { key: "momento", label: "Aguardando Deliberação", value: "AD" }
];

const autorItens: IListFilterInputItem[] = [
    // { key: "", label: "-- Busque e Selecione -- ", value: "" }
];

const relatorItens: IListFilterInputItem[] = [
    // { key: "", label: "-- Busque e Selecione -- ", value: "" }
];

const influenciadoItens: IListFilterInputItem[] = [
    // { key: "", label: "-- Busque e Selecione -- ", value: "" }
];

const posicionamentoItens: IListFilterInputItem[] = [
    { key: "posicionamento", label: "Contrário", value: "1" },
    { key: "posicionamento", label: "Contrário com Emenda Saneadora", value: "2" },
    { key: "posicionamento", label: "Neutro", value: "3" },
    { key: "posicionamento", label: "Favorável com Emenda", value: "4" },
    { key: "posicionamento", label: "Favorável", value: "5" },
    { key: "posicionamento", label: "Favorável ao parecer do relator", value: "6" },
    { key: "posicionamento", label: "Favorável ao parecer do relator com emenda", value: "7" },
    { key: "posicionamento", label: "Contrário ao parecer do relator", value: "8" },
    { key: "posicionamento", label: "Contrário ao parecer do relator com emenda", value: "9" },
    { key: "posicionamento", label: "Pendente", value: "99" },
    { key: "posicionamento", label: "Indefinido", value: "0" },
];

const resultadoItens: IListFilterInputItem[] = [
    { key: "resultado", label: "Tramitando", value: "Tramitando" },
    { key: "resultado", label: "Finalizado", value: "Finalizado" },
];

const resultadoStatusItens: IListFilterInputItem[] = [
    { key: "resultado_status", label: "Positivo", value: "Positivo" },
    { key: "resultado_status", label: "Negativo", value: "Negativo" },
    { key: "resultado_status", label: "Neutro", value: "Neutro" },
];

const acaoItens: IListFilterInputItem[] = [
    { key: "acao_acontecimento", label: "Positivo", value: "Positivo" },
    { key: "acao_acontecimento", label: "Negativo", value: "Negativo" },
    { key: "acao_acontecimento", label: "Neutro", value: "Neutro" },
];

const prioridadeItens: IListFilterInputItem[] = [
    { key: "prioridade", label: "Mínima", value: "1" },
    { key: "prioridade", label: "Baixa", value: "2" },
    { key: "prioridade", label: "Média", value: "3" },
    { key: "prioridade", label: "Alta", value: "4" },
    { key: "prioridade", label: "Máxima", value: "5" },
    { key: "prioridade", label: "Indefinido", value: "0" },
];

// const alturaDinamica = window.screen.height - 150;

const MainGrid = styled(Grid)`
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    max-height: 300px;
`;

const FiltroDiv = styled.div`
    max-width: 500px;
	overflow-y: auto; 
	.MuiAccordionSummary-root {
		justify-content: flex-start;

		.MuiAccordionSummary-content {
			flex-grow: unset;
		}
	}
	div.MuiPaper-elevation:first-child {
		.MuiAccordionSummary-expandIconWrapper {
			display: none;
		}
	}
	div.MuiFormGroup-root {
		flex-direction: row;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	span.Mui-checked {
		color: #160342;
	}
`;

const TitleLabel = styled(Typography)`
	width: 100%;
	font-weight: 600;
	font-size: 14px;
	color: #4b1196;
	margin-top: 20px;
	margin-bottom: 20px;
    margin-left: 10px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey',
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'grey',
        },
    },
    checkbox: {
        '&.Mui-checked': {
            color: 'grey',
        },
    },
    label: {
        '&.Mui-focused': {
            color: 'grey',
        },
    },
}));

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey',
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'grey',
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: 'grey',
        },
        '& .MuiInputLabel-shrink': {
            color: 'grey',
        },
    },
})(TextField);


export default function ProposicaoListFilterItens(props: {
    filtrarAction?: () => void,
    filter: IFilterData[];
    onChangeFilter: (data: IFilterData[]) => void;
    idPermissao?: number;
}): JSX.Element {
    const tramitacaoRef = useRef(null);
    const visualizacaoRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const { pallet } = usePallet();
    const { user, getConfig } = useAuthorization();

    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const [estados, setEstados] = React.useState<Array<IListFilterInputItem>>([]);
    const [municipio, setMunicipio] = React.useState<Array<IListFilterInputItem>>([]);
    const [municipioNew, setMunicipioNew] = React.useState<Array<IListFilterInputItemNew>>([]);
    const [casaLegislativa, setCasaLegislativa] = React.useState('');
    const [ambitos, setAmbitos] = React.useState<Array<IListFilterInputItem>>([]);
    const [tipo, setTipo] = React.useState<Array<IListFilterInputItem>>([]);
    const [tema, setTema] = React.useState<Array<IListFilterInputItem>>([]);
    const [autorParcial, setAutorParcial] = React.useState<string>();
    const [relatorParcial, setRelatorParcial] = React.useState<string>();
    const [influenciadoParcial, setInfluenciadoParcial] = React.useState<string>();
    const [autorLista, setAutorLista] = React.useState([]);
    const [autorListaRelator, setAutorListaRelator] = React.useState([]);
    const [autorListaInfluenciado, setAutorListaInfluenciado] = React.useState([]);
    const [grupo, setGrupo] = React.useState([]);
    const [listTemaByGrupo, setListTemaByGrupo] = React.useState([]);
    const [msgNoTema, setMsgNoTema] = React.useState(false);
    const [listSubtemaByTema, setListSubtemaByTema] = React.useState([]);
    const [listTopicoBySubtema, setListTopicoBySubtema] = React.useState([]);
    const [msgNoSubtema, setMsgNoSubtema] = React.useState(false);
    const [msgNoTopico, setMsgNoTopico] = React.useState(false);
    const [loadingAutor, setLoadingAutor] = React.useState(false);
    const [loadingRelator, setLoadingRelator] = React.useState(false);
    const [loadingInfluenciado, setLoadingInfluenciado] = React.useState(false);
    const [openAutor, setOpenAutor] = React.useState(false);
    const [openRelator, setOpenRelator] = React.useState(false);
    const [openInfluenciado, setOpenInfluenciado] = React.useState(false);
    const [listaAcao, setListaAcao] = React.useState<Array<any>>([]);
    const [federalOuOutros, setFederalOuOutros] = React.useState<string>(''); // federal || outros
    const [valueRadioDate, setValueRadioDate] = React.useState(
        props.filter.find((item) => item.key === "dataIni" || item.key === "dataFin") ? "apresentacao"
            :
            props.filter.find((item) => item.key === "dataAtualizacaoIni" || item.key === "dataAtualizacaoFin") ? "atualizacao"
                :
                props.filter.find((item) => item.key === "dataIniTramitacao" || item.key === "dataFinTramitacao") ? "tramitacao"
                    :
                    props.filter.find((item) => item.key === "dataCargaTramitacaoIni" || item.key === "dataCargaTramitacaoFin") ? "cargaTramitacao"
                        :
                        'tramitacao'
    );

    const itemGrupos = props.filter.filter((item) => item.key === "grupos");
    const itemTemas = props.filter.filter((item) => item.key === "temas");
    const itemSubtemas = props.filter.filter((item) => item.key === "subtemas");
    const itemTopicos = props.filter.filter((item) => item.key === "topicos");


    React.useEffect(() => {
        TabelasService.getTabelaEstados().then(
            res => {
                const data = []
                if (getConfig()?.habPropFiltroMult) {
                    res?.map(item => data.push({ id: item.id, key: item.sigla, label: item.nome, value: item.sigla }))
                } else {
                    res?.map(item => data.push({ key: item.sigla, label: item.nome, value: item.sigla }))
                }
                setEstados(() => [...data]);
            })
        TabelasService.getProposicaoTipos().then(
            res => {
                const data = []
                res?.map(item => data.push({ key: item, label: item, value: item }))
                setTipo(() => [...data]);
            })
        TabelasService.getProposicaoTemasLegislativos(0).then( //Legislativo
            res => {
                const data = []
                if (res) res.map(item => data.push({ key: 'tema_legislativo', label: item.descricao, value: item.codigo }))
                setTema(() => [...data]);
            })

        const casaFilter = props.filter.filter((item) => item.key === "casa");
        let subCasa
        switch (casaFilter[0]?.value) {
            case "SF":
                subCasa = 1;
                break;
            case "CD":
                subCasa = 2;
                break;
            case "CN":
                subCasa = 3;
                break;
        }

        TabelasService.getAcontecimentoStatus().then(async (res) => {
            const data = [];
            res?.map((item) =>
                data.push({
                    key: item.descricao,
                    label: item.descricao,
                    value: item.descricao,
                })
            );
            setListaAcao(() => [...data]);
        });


        TabelasService.getTabelaAmbitosTipo(casaFilter.length ? subCasa : 0).then( //CASA
            res => {
                const data = []
                res?.map(item => data.push({ key: item.sigla, label: item.sigla + ' - ' + item.descricao, value: item.sigla }))
                setAmbitos(() => [...data]);
            })

        TabelasService.getProposicaoGrupoTemas().then(
            res => {
                const data = []
                res?.map(item => data.push({ key: item.id, label: item.descricao, value: item.id }));
                setGrupo(data);
            })


        if (itemTemas.length > 0) {
            TabelasService.getProposicaoTemasByGrupo(itemGrupos[0]?.value).then(
                res => {
                    if (res?.length > 0) {
                        setMsgNoTema(false)
                        const data = []
                        res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                        setListTemaByGrupo(data);
                        if (itemSubtemas.length > 0) {

                            TabelasService.getProposicaoSubTemasByTema(itemTemas[0]?.value).then(
                                res => {
                                    if (res?.length > 0) {
                                        setMsgNoSubtema(false)
                                        const data = []
                                        res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                                        setListSubtemaByTema(data);
                                        if (itemTopicos.length > 0) {

                                            TabelasService.getProposicaoTopicosBySubtema(itemSubtemas[0]?.value).then(
                                                res => {
                                                    if (res?.length > 0) {
                                                        setMsgNoTopico(false)
                                                        const data = []
                                                        res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                                                        setListTopicoBySubtema(data);
                                                    } else {
                                                        setMsgNoTopico(true)
                                                    }
                                                })
                                        }
                                    } else {
                                        setMsgNoSubtema(true)
                                    }
                                })
                        }

                    } else {
                        setMsgNoTema(true)
                    }
                })


        }


    }, []);

    useEffect(() => {
        if (getConfig()?.habPropFiltroMult) {
            const fetchData = async () => {
                const estadoFilter = props.filter.find(item => item.key === 'estado');

                if (estadoFilter && estadoFilter.value.length > 0 && check("esfera", "Municipal")) {
                    let listMunicipios = [];
                    await Promise.all(estadoFilter.value.map(async estado => {
                        try {
                            let array: any = [estado];
                            const res = await TabelasService.getTabelaMunicipios(array);
                            const data = res.map(item => ({
                                id: item.id,
                                key: item.nome,
                                label: `${estado} | ${item.nome}`,
                                value: `${estado} | ${item.nome}`,
                                cod: item.sigla
                            }));

                            listMunicipios.push(...data);
                        } catch (error) {
                            console.error(`Erro ao buscar municípios para o estado ${estado}:`, error);
                        }
                    }));
                    setMunicipioNew(listMunicipios);
                };
            };

            fetchData();
        } else {
            const estadoFilter = props.filter.filter(item => item.key === 'estado');

            if (estadoFilter && estadoFilter.length && check("esfera", "Municipal")) {
                TabelasService.getTabelaMunicipios(estadoFilter[0].value).then(
                    res => {
                        const data = []
                        res?.map(item => data.push({ key: item.nome, label: item.nome, value: item.sigla }));
                        setMunicipio(() => [...data]);
                    })
            }
        }

    }, [props.filter]);


    useEffect(() => {
        if (autorParcial && autorParcial.length && autorParcial.trim().length > 2) {
            ProposicaoService.GetPartialAutor(autorParcial, props.filter)
                .then(async res => {
                    if (res) setAutorLista(res);
                });
        }
        else if (autorParcial === "") {
            setAutorLista(autorItens);
            const newFilter = props.filter.filter(item => item.key !== "autor" && item.key !== "autorP");
            props.onChangeFilter(newFilter);
        }
    }, [autorParcial]);

    useEffect(() => {
        if (relatorParcial && relatorParcial.length && relatorParcial.trim().length > 2) {
            ProposicaoService.GetPartialRelator(relatorParcial, props.filter)
                .then(res => {
                    if (res && res.length > 0) {
                        setAutorListaRelator(res.map(x => {
                            return x.proposicao_autor_nome
                        }));
                    };
                });
        }
        else if (relatorParcial === "") {
            setAutorListaRelator(relatorItens);
            const newFilter = props.filter.filter(item => item.key !== "relator");
            props.onChangeFilter(newFilter);
        }
    }, [relatorParcial]);

    useEffect(() => {

        if (influenciadoParcial && influenciadoParcial.length && influenciadoParcial.trim().length > 2) {
            ProposicaoService.GetPartialInfluenciado(influenciadoParcial, props.filter)
                .then(async res => {
                    if (res && res.length > 0) {
                        setAutorListaInfluenciado(res.map(x => {
                            return { key: x.id, label: x.proposicao_autor_nome, value: x.id }
                        }));
                    };
                });
        }
        else if (influenciadoParcial === "") {
            setAutorListaInfluenciado(influenciadoItens);
            const newFilter = props.filter.filter(item => item.key !== "influenciado");
            props.onChangeFilter(newFilter);
        }
    }, [influenciadoParcial]);

    const handleChangeOpen = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) =>
        setExpanded(newExpanded ? panel : false);


    const check = (key: string, value: any): boolean => {
        if (!getConfig()?.habPropFiltroMult) {
            const item = props.filter.filter((item) => item.key === key);
            return item.length === 1 && item[0].value === value;
        };
        if (getConfig()?.habPropFiltroMult) {
            const item = props.filter.filter((item) => item.key === key);
            return item.length === 1 && item[0].value.filter(x => x === value).length > 0;
        };
    };

    const handleWrapper = (updatedFilter: IFilterData[]) => {
        handleTramitacaoOnClick({}, '', true)

        if (tramitacaoRef.current) {
            tramitacaoRef.current.clear('emTramitacao');
        };

        if (visualizacaoRef.current) {
            visualizacaoRef.current.clear('visualizacao');
        };

        props.onChangeFilter(updatedFilter.filter(x => x.key === 'esfera'));
    };

    const handleMunicipioOnClickNew = (event, optionsValue, optionsId, optionsCod) => {
        const values = props.filter.filter((item) => item.key !== "municipio");
        values.push({
            key: event.target.name,
            label: `${event.target.name.toUpperCase()}: ${optionsValue}`,
            value: optionsValue,
            id: optionsId
        });
        props.onChangeFilter(values);
    };

    const handleGenericOnClick = (data: any, value: any) => {

        if (!getConfig()?.habPropFiltroMult) {
            const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
            if (value && data.target.name)
                values.push({
                    key: data.target.name,
                    label: `${data.target.name.toUpperCase()}: ${value}`,
                    value: value,
                });
            else if (value && data.target.id) {
                values.filter(item => item.key !== data.target.id)

                if (value) values.push({ key: data.target.id, label: `${data.target.id.toUpperCase()}: ${data.target.value}`, value: value })
            }

            props.onChangeFilter(values);

        };

        if (getConfig()?.habPropFiltroMult) {
            if (data && data.target) {
                const eventTarget = data.target;
                const name = eventTarget?.name;
                const id = eventTarget?.id;
                const fieldValue = (value !== undefined) ? value : eventTarget.value;

                const values = props.filter.filter((item) => item.key !== name && item.key !== id);
                if (fieldValue && name) {
                    if (props.filter.filter((item) => item.key === 'posicionamento').length > 0) {
                        values.push({
                            key: name,
                            label: `${name.toUpperCase()}: ${fieldValue}`,
                            value: fieldValue,
                        });
                    } else {
                        values.push({
                            key: name,
                            label: `${name.toUpperCase()}: ${fieldValue}`,
                            value: fieldValue,
                        });
                    }

                } else if (fieldValue && id) {
                    if (props.filter.filter((item) => item.key === 'posicionamento').length > 0) {
                        values.push({
                            key: id,
                            label: `${id.toUpperCase()}: ${fieldValue}`,
                            value: fieldValue,
                        });
                    } else {
                        values.push({
                            key: id,
                            label: `${id.toUpperCase()}: ${fieldValue}`,
                            value: fieldValue,
                        });
                    }
                }

                props.onChangeFilter(values);
            } else {
                console.error('target não definido');
            }
        };
    };



    const handleTramitacaoOnClick = (data: any, value: any, fromEsfera?: boolean) => {
        if (fromEsfera) {
            props.onChangeFilter(props.filter.filter(x => x.key === "emTramitacao"));

        } else {
            const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
            switch (value) {
                case "tramitando":
                    values.push({
                        key: data.target.name,
                        label: "Tramitando",
                        value: value,
                    })
                    break;
                case "naoTramitando":
                    values.push({
                        key: data.target.name,
                        label: "Não tramitando",
                        value: value,
                    })
                    break;
                case "perdaEficacia":
                    values.push({
                        key: data.target.name,
                        label: "Perda de eficácia",
                        value: value,
                    })
                    break;
            }
            props.onChangeFilter(values);
        }
    };

    const handleCasaOnClick = (data: any, value: any) => {

        setCasaLegislativa(value);

        const values = props.filter.filter(item => item.key !== 'casa' && item.key !== 'ambito')
        if (value) {

            let ambitoId = 0;

            switch (value) {
                case "SF":
                    ambitoId = 1;
                    values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Senado', value: value })
                    break;
                case "CD":
                    ambitoId = 2;
                    values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Câmara', value: value })
                    break;
                case "CN":
                    ambitoId = 3;
                    values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Congresso', value: value })
                    break;
            }
            TabelasService.getTabelaAmbitosTipo(ambitoId).then(
                res => {
                    const data = []
                    res?.map(item => data.push({ key: item.sigla, label: item.sigla + ' - ' + item.resumo, value: item.sigla }))
                    setAmbitos(() => [...data]);
                })
        };

        props.onChangeFilter(values);
    };

    const handleEstadoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== "estado" && item.key !== "municipal");

        if (value) {
            values.push({
                key: data.target.name,
                label: `${data.target.name.toUpperCase()}: ${value}`,
                value: value,
            });

            TabelasService.getTabelaMunicipios(value).then(
                res => {
                    const data = []
                    res?.map(item => data.push({ key: item.nome, label: item.nome, value: item.sigla }));
                    setMunicipio(() => [...data]);
                })
        }

        props.onChangeFilter(values);
    };

    const handleEstadoOnClickMult = async (data: any, value: any, id: any) => {
        const values = props.filter.filter((item) => item.key !== "estado" && item.key !== "municipal");

        values.push({
            key: data.target.name,
            label: `${data.target.name.toUpperCase()}: ${value}`,
            value: value,
            id: id
        });

        let listMunicipios = [];
        await value.forEach(estado => {
            TabelasService.getTabelaMunicipios(estado).then(
                res => {
                    const data = []
                    res?.map(item => data.push({ id: item.id, key: item.nome, label: item.nome, value: item.nome, cod: item.sigla }));
                    listMunicipios.push(() => [...data])
                })
        });

        setMunicipioNew(listMunicipios);

        props.onChangeFilter(values);
    };


    const handleMunicipioOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: `${data.target.name.toUpperCase()}: ${municipio.filter(item => item.value === value)[0]?.label}`,
                value: value,
            });
        props.onChangeFilter(values);
    };

    const handlePrioridadeOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: `${data.target.name.toUpperCase()}: ${value}`,
                value: value,
            });
        else if (value && data.target.id) {
            values.filter(item => item.key !== data.target.id)

            if (value) values.push({ key: data.target.id, label: `${data.target.id.toUpperCase()}: ${data.target.value}`, value: value })
        }

        props.onChangeFilter(values);
    };

    const handleMomentoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: `${data.target.name.toUpperCase()}: ${momentoItens.filter(item => item.value === value)[0].label}`,
                value: value,
            });
        props.onChangeFilter(values);
    };

    const handleDataApresentacaoOnClick = (data: any, value: any) => {

        const values = props.filter.filter((item) =>
            item.key !== "dataIni" &&
            item.key !== "dataFin" &&
            item.key !== "dataCargaTramitacaoIni" &&
            item.key !== "dataCargaTramitacaoFin" &&
            item.key !== "dataFinTramitacao" &&
            item.key !== "dataIniTramitacao" &&
            item.key !== "dataAtualizacaoIni" &&
            item.key !== "dataAtualizacaoFin"
        );

        if (value[1] && Object.prototype.toString.call(value[1]) === '[object Date]' && !isNaN(value[1].getTime())) {
            values.push({
                key: "dataFin",
                label: `DATA APRESENTAÇÃO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                value: formatDate(value[1], 'MM/dd/yyyy'),
            });
        }
        values.push({
            key: "dataIni",
            label: `DATA APRESENTAÇÃO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
            value: formatDate(value[0], 'MM/dd/yyyy'),
        });

        if (values.length > 1) {
            props.onChangeFilter(values);
        }
    };

    const handleDataTramitacaoOnClick = (data: any, value: any) => {

        const values = props.filter.filter((item) =>
            item.key !== "dataIni" &&
            item.key !== "dataFin" &&
            item.key !== "dataCargaTramitacaoIni" &&
            item.key !== "dataCargaTramitacaoFin" &&
            item.key !== "dataFinTramitacao" &&
            item.key !== "dataIniTramitacao" &&
            item.key !== "dataAtualizacaoIni" &&
            item.key !== "dataAtualizacaoFin"
        );

        if (value[1] && Object.prototype.toString.call(value[1]) === '[object Date]' && !isNaN(value[1].getTime())) {
            values.push({
                key: "dataFinTramitacao",
                label: `DATA TRAMITAÇÃO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                value: formatDate(value[1], 'MM/dd/yyyy'),
            });
        }
        values.push({
            key: "dataIniTramitacao",
            label: `DATA TRAMITAÇÃO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
            value: formatDate(value[0], 'MM/dd/yyyy'),
        });

        if (values.length > 1) {
            props.onChangeFilter(values);
        }
    };

    const handleDataAtualizacaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) =>
            item.key !== "dataIni" &&
            item.key !== "dataFin" &&
            item.key !== "dataCargaTramitacaoIni" &&
            item.key !== "dataCargaTramitacaoFin" &&
            item.key !== "dataFinTramitacao" &&
            item.key !== "dataIniTramitacao" &&
            item.key !== "dataAtualizacaoIni" &&
            item.key !== "dataAtualizacaoFin"
        );

        if (value && data.target.name) {
            values.push({
                key: "dataAtualizacaoIni",
                label: `DATA ATUALIZAÇÃO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
                value: formatDate(value[0], 'MM/dd/yyyy'),
            });
            if (value.length > 1 && value[1]) {
                values.push({
                    key: "dataAtualizacaoFin",
                    label: `DATA ATUALIZAÇÃO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                    value: formatDate(value[1], 'MM/dd/yyyy'),
                });
            }
        }
        props.onChangeFilter(values);
    };

    const handleCargaTramitacaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) =>
            item.key !== "dataIni" &&
            item.key !== "dataFin" &&
            item.key !== "dataCargaTramitacaoIni" &&
            item.key !== "dataCargaTramitacaoFin" &&
            item.key !== "dataFinTramitacao" &&
            item.key !== "dataIniTramitacao" &&
            item.key !== "dataAtualizacaoIni" &&
            item.key !== "dataAtualizacaoFin"
        );

        if (value && data.target.name) {
            values.push({
                key: "dataCargaTramitacaoIni",
                label: `DATA CARGA TRAMITAÇÃO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
                value: formatDate(value[0], 'MM/dd/yyyy'),
            });
            if (value.length > 1 && value[1]) {
                values.push({
                    key: "dataCargaTramitacaoFin",
                    label: `DATA CARGA TRAMITAÇÃO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                    value: formatDate(value[1], 'MM/dd/yyyy'),
                });
            }
        }
        props.onChangeFilter(values);
    };

    const handleDataPeriodoAtividadeOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== "periodoAtividadeIni" && item.key !== "periodoAtividadeFin");
        if (value && data.target.name) {
            values.push({
                key: "periodoAtividadeIni",
                label: `PEDÍODO AÇÃO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
                value: formatDate(value[0], 'MM/dd/yyyy'),
            });
            if (value.length > 1 && value[1]) {
                values.push({
                    key: "periodoAtividadeFin",
                    label: `PEDÍODO AÇÃO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                    value: formatDate(value[1], 'MM/dd/yyyy'),
                });
            }
        }
        props.onChangeFilter(values);
    };


    const handleTemaOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: `${tema.filter(item => item.value === value)[0].label}`,
                value: String(data.target.value),
            });
        props.onChangeFilter(values);
    };

    const handlePosicionamentoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: `${data.target.name.toUpperCase()}: ${value}`,
                value: value,
            });
        else if (value && data.target.id) {
            values.filter(item => item.key !== data.target.id)

            if (value) values.push({ key: data.target.id, label: `${data.target.id.toUpperCase()}: ${data.target.value}`, value: value })
        }

        props.onChangeFilter(values);
    };

    // handleAutorKeyPress é quando estou escrevendo no campo
    const handleAutorKeyPress = async (event) => {
        const newFilters = props.filter.filter((item) => item.key !== "autor");
        if (event.charCode === 13) {
            setLoadingAutor(true)
            setAutorParcial(autorValueChange(event.target.value))
            await ProposicaoService.GetPartialAutor(event.target.value, newFilters)
                .then(res => {
                    setAutorLista(res);
                    setLoadingAutor(false)
                });
        }
    };

    // handleAutorChange é quando clico em um item da lista
    const handleAutorChange = (data, value, reason) => {
        setAutorParcial(autorValueChange(value));
        const values = props.filter.filter((item) => item.key !== "autor");
        if (value && data.target.innerHTML) {
            values.push({
                key: "autor",
                label: `AUTOR: ${value}`,
                value: value,
            });
        }
        props.onChangeFilter(values);
        // reason abaixo é o X de dentro do campo
        if (reason === 'clear') {
            setAutorParcial(autorValueChange(''));
        }
    };


    const handleRelatorKeyPress = async (event) => {
        const newFilters = props.filter.filter((item) => item.key !== "relatorF");
        if (event.charCode === 13) {
            setLoadingRelator(true)
            setRelatorParcial(relatorValueChange(event.target.value))
            await ProposicaoService.GetPartialRelator(event.target.value, newFilters)
                .then(res => {
                    setAutorListaRelator(res);
                    setLoadingRelator(false)
                });
        }
    };


    const handleRelatorChange = (data, value, reason) => {
        setRelatorParcial(relatorValueChange(value));
        const values = props.filter.filter((item) => item.key !== "relatorF");
        if (value && data.target.innerHTML) {
            values.push({
                key: "relatorF",
                label: `RELATOR: ${value}`,
                value: value,
            });
        }
        props.onChangeFilter(values);
        if (reason === 'clear') {
            setRelatorParcial(relatorValueChange(''));
        }
    };


    const handleInfluenciadoKeyPress = async (event) => {
        const newFilters = props.filter.filter((item) => item.key !== "influenciadoF");
        if (event.charCode === 13) {
            setLoadingInfluenciado(true)
            setInfluenciadoParcial(influenciadoValueChange(event.target.value))
            await ProposicaoService.GetPartialInfluenciado(event.target.value, newFilters)
                .then(res => {
                    setAutorListaInfluenciado(res)
                    setLoadingInfluenciado(false)
                });
        }
    };


    const handleInfluenciadoChange = (data, value, reason) => {
        setInfluenciadoParcial(influenciadoValueChange(value));
        const values = props.filter.filter((item) => item.key !== 'influenciadoF');
        if (value && data.target.innerHTML)
            values.push({
                key: "influenciadoF",
                label: `INFLUENCIADO: ${value.label}`,
                value: value.label,
            });
        else if (value?.label && value?.key) {
            values.filter(item => item.key !== value?.key)
            if (value?.label) values.push({ key: "influenciadoF", label: `INFLUENCIADO: ${value?.label}`, value: value?.label })
        }
        props.onChangeFilter(values);
        if (reason === 'clear') {
            setInfluenciadoParcial(influenciadoValueChange(''));
        }

    }

    const handleGrupoOnClick = (data: any, value: any) => {
        setListTemaByGrupo([])
        setListSubtemaByTema([])
        setListTopicoBySubtema([])

        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id && item.key !== "temas" && item.key !== "subtemas" && item.key !== "topicos");
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: "GRUPO: " + `${grupo.filter(item => item.value === value)[0].label}`,
                value: value,
            });
        props.onChangeFilter(values);



        TabelasService.getProposicaoTemasByGrupo(value).then(
            res => {
                if (res.length > 0) {
                    setMsgNoTema(false)
                    const data = []
                    res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                    setListTemaByGrupo(data);
                } else {
                    setMsgNoTema(true)
                }
            })

    };


    const handleTemaByGrupoOnClick = (data: any, value: any) => {
        setListSubtemaByTema([])
        setListTopicoBySubtema([])
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id && item.key !== "subtemas");
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: "TEMA: " + `${listTemaByGrupo.filter(item => item.value === value)[0].label}`,
                value: value,
            });
        props.onChangeFilter(values);



        TabelasService.getProposicaoSubTemasByTema(value).then(
            res => {
                if (res.length > 0) {
                    setMsgNoSubtema(false)
                    const data = []
                    res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                    setListSubtemaByTema(data);
                } else {
                    setMsgNoSubtema(true)
                }
            })

    };


    const handleSubtemaByTemaOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: "SUBTEMA: " + `${listSubtemaByTema.filter(item => item.value === value)[0].label}`,
                value: value,
            });
        props.onChangeFilter(values);

        TabelasService.getProposicaoTopicosBySubtema(value).then(
            res => {
                if (res.length > 0) {
                    setMsgNoTopico(false)
                    const data = []
                    res.map(item => data.push({ key: item.descricao, label: item.descricao, value: item.id }));
                    setListTopicoBySubtema(data);
                } else {
                    setMsgNoTopico(true)
                }
            })
    };

    const handleTopicoBySubtemaOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: "TOPICO: " + `${listTopicoBySubtema.filter(item => item.value === value)[0].label}`,
                value: value,
            });
        props.onChangeFilter(values);
    };


    // >>>>>>>> COMPONENTE MULTIPLO <<<<<<<<<<<<<


    const Label = styled('label')`
        padding: 0 0 4px;
        line-height: 1.5;
        display: block;
        color: 'red'
        `;

    const InputWrapper = styled('div')`
        width: 300px;
        border: 1px solid '#d9d9d9';
        background-color: '#8888';
        border-radius: 4px;
        padding: 1px;
        display: flex;
        flex-wrap: wrap;

        &:hover {
            border-color: '#40a9ff';
        }

        &.focused {
            border-color: '#40a9ff';
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }

        & input {
            background-color: 'grey';
            color: 'rgba(0,0,0,.85)';
            height: 30px;
            box-sizing: border-box;
            padding: 4px 6px;
            width: 0;
            min-width: 30px;
            flex-grow: 1;
            border: 0;
            margin: 0;
            outline: 0;
        }
        `

    interface TagProps extends ReturnType<AutocompleteGetTagProps> {
        label: string;
    }

    function Tag(props: TagProps) {
        const { label, onDelete, ...other } = props;
        return (
            <div {...other}>
                <span>{label}</span>
                <CloseIcon onClick={onDelete} />
            </div>
        );
    }

    const StyledTag = styled(Tag)<TagProps>(
        ({ theme }) => `
    display: flex;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: '#fafafa';
    border: 1px solid '#e8e8e8';
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;

    &:focus {
        border-color: '#40a9ff';
        background-color: '#e6f7ff';
    }

    & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & svg {
        font-size: 12px;
        cursor: pointer;
        padding: 4px;
    }
    `,
    );


    const Listbox = styled('ul')(
        ({ theme }) => `
        width: 300px;
        margin: 2px 0 0;
        padding: 0;
        position: absolute;
        list-style: none;
        background-color: '#fff';
        overflow: auto;
        max-height: 250px;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 1;

        & li {
            padding: 5px 12px;
            display: flex;

            & span {
            flex-grow: 1;
            }

            & svg {
            color: transparent;
            }
        }

        & li[aria-selected='true'] {
            background-color: '#fafafa';
            font-weight: 600;

            & svg {
            color: #1890ff;
            }
        }

        & li.${autocompleteClasses.focused} {
            background-color: '#e6f7ff';
            cursor: pointer;

            & svg {
            color: currentColor;
            }
        }
        `,
    );

    const autorValueChange = (value) => {
        if (value) {
            return value
        } else if (props.filter.filter(x => x.key === 'autor').length > 0) {
            return props.filter.filter(x => x.key === 'autor')[0].value
        } else {
            return ''
        }
    };

    const relatorValueChange = (value) => {
        if (value) {
            return value
        } else if (props.filter.filter(x => x.key === 'relatorF').length > 0) {
            return props.filter.filter(x => x.key === 'relatorF')[0].value
        } else {
            return ''
        }
    };

    const influenciadoValueChange = (value) => {
        if (value) {
            return value
        } else if (props.filter.filter(x => x.key === 'influenciadoF').length > 0) {
            return props.filter.filter(x => x.key === 'influenciadoF')[0].value
        } else {
            return ''
        }
    };

    const handleChangeDateApresEAtua = async (event: React.ChangeEvent<HTMLInputElement>) => {

        await setValueRadioDate((event.target as HTMLInputElement).value);
        props.onChangeFilter(props.filter.filter(
            x => !['dataIni', 'dataFin', 'dataAtualizacaoIni', 'dataAtualizacaoFin'].includes(x.key)
        ));
    };

    return (
        <>
            <FiltroDiv>
                <FilterItemPanel panel="panel1" name="data-referencia" label="Período Obrigatório (Max. 1 mês)" expanded="data-referencia">
                    {getConfig()?.habPropFiltroMult
                        ?
                        <Grid style={{ backgroundColor: pallet.backgroundColorQuintenary, width: '29rem', padding: '0px 0px 0px 3px', marginBottom: '8px', borderRadius: '5px' }}>
                            <Grid>
                                <FormControl style={{ padding: '15px 10px 5px 10px', width: '99.5%' }}>
                                    <Select
                                        fullWidth
                                        variant='outlined'
                                        id="date-select"
                                        value={valueRadioDate}
                                        onChange={handleChangeDateApresEAtua}
                                        size="medium"
                                        style={{ width: '100%', backgroundColor: 'transparent', paddingTop: '3px', marginBottom: '-3px' }}

                                    >
                                        <MenuItem value="apresentacao">
                                            <span style={{
                                                fontSize: pallet.general.size16,
                                                fontWeight: pallet.general.weight_300
                                            }}>
                                                Data de Apresentação
                                            </span>
                                        </MenuItem>
                                        <MenuItem value="atualizacao">
                                            <span style={{
                                                fontSize: pallet.general.size16,
                                                fontWeight: pallet.general.weight_300
                                            }}>
                                                Data de Carga (Data Atualização)
                                            </span>
                                        </MenuItem>
                                        <MenuItem value="tramitacao">
                                            <span style={{
                                                fontSize: pallet.general.size16,
                                                fontWeight: pallet.general.weight_300
                                            }}>
                                                Data da Última Tramitação
                                            </span>
                                        </MenuItem>
                                        <MenuItem value="cargaTramitacao">
                                            <span style={{
                                                fontSize: pallet.general.size16,
                                                fontWeight: pallet.general.weight_300
                                            }}>
                                                Data de Carga da Última Tramitação
                                            </span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid style={{ marginTop: '10px' }}>
                                {valueRadioDate === 'apresentacao'
                                    ?
                                    <ListFilterRangeDatePickerItem
                                        origin={'proposicao'}
                                        backgroundColor={false}
                                        clear={valueRadioDate}
                                        label="Data Apresentação"
                                        currentFilter={props.filter}
                                        filterKey="dataApresentacao"
                                        onChange={handleDataApresentacaoOnClick}
                                    />
                                    :
                                    null}
                                {valueRadioDate === 'tramitacao'
                                    ?
                                    <ListFilterRangeDatePickerItem
                                        origin={'proposicao'}
                                        backgroundColor={false}
                                        clear={valueRadioDate}
                                        label="Última Tramitação"
                                        currentFilter={props.filter}
                                        filterKey="dataTramitacao"
                                        onChange={handleDataTramitacaoOnClick}
                                    />
                                    :
                                    null}
                                {valueRadioDate === 'atualizacao'
                                    ?
                                    <ListFilterRangeDatePickerItem
                                        origin={'proposicao'}
                                        backgroundColor={false}
                                        clear={valueRadioDate}
                                        label="Data Atualização"
                                        currentFilter={props.filter}
                                        filterKey="dataAtualizacao"
                                        onChange={handleDataAtualizacaoOnClick}
                                        keyIni={'dataAtualizacaoIni'}
                                        keyFin={'dataAtualizacaoFin'}
                                    />
                                    :
                                    null
                                }
                                {valueRadioDate === 'cargaTramitacao'
                                    ?
                                    <ListFilterRangeDatePickerItem
                                        origin={'proposicao'}
                                        backgroundColor={false}
                                        clear={valueRadioDate}
                                        label="Carga Última Tramitação"
                                        currentFilter={props.filter}
                                        filterKey="dataCargaTramitacao"
                                        onChange={handleCargaTramitacaoOnClick}
                                        keyIni={'dataCargaTramitacaoIni'}
                                        keyFin={'dataCargaTramitacaoFin'}
                                    />
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                        :
                        null
                    }

                </FilterItemPanel>

                <FilterItemPanel panel="panel1" name="esfera" label="Esfera" expanded="esfera">
                    {getConfig()?.habPropFiltroMult
                        ?
                        <ListFilterCheckboxItemEsfera2
                            label="Esfera"
                            currentFilter={props.filter}
                            filterKey="esfera"
                            items={esferaItens}
                            onChange={handleWrapper}
                        />
                        :
                        <ListFilterRadioItem
                            label="Esfera"
                            currentFilter={props.filter}
                            filterKey="esfera"
                            itens={esferaItens}
                            onChange={handleGenericOnClick}
                        />
                    }

                </FilterItemPanel>

                {/* Poder Legislativo */}
                <FilterItemPanel
                    panel="panel2"
                    name="poder"
                    label="Poder Legislativo"
                    onOpen={handleChangeOpen}
                >

                    <ListFilterInputItem
                        ref={tramitacaoRef}
                        label="Tramitação"
                        currentFilter={props.filter}
                        filterKey="emTramitacao"
                        itens={tramitacaoItens}
                        onChange={handleTramitacaoOnClick}
                    />
                    {props.idPermissao !== 4 ?

                        <ListFilterInputItem
                            ref={visualizacaoRef}
                            label="Visualização"
                            currentFilter={props.filter}
                            filterKey="visualizacao"
                            itens={visualizacaoItens}
                            onChange={handleGenericOnClick}
                        />

                        :
                        null

                    }



                    {(check("esfera", "Estadual") || check("esfera", "Municipal")) && (
                        getConfig()?.habPropFiltroMult
                            ?
                            <MultipleListFilterInputItem
                                label="Estado"
                                currentFilter={props.filter}
                                filterKey="estado"
                                itens={estados}
                                onChange={handleEstadoOnClickMult}
                            />
                            :
                            <ListFilterInputItem
                                label="Estado"
                                currentFilter={props.filter}
                                filterKey="estado"
                                itens={estados}
                                onChange={handleEstadoOnClick}
                            />
                    )}

                    {check("esfera", "Federal") && (
                        <>
                            <ListFilterInputItem
                                label="Casa Legislativa"
                                currentFilter={props.filter}
                                filterKey="casa"
                                itens={casaItens}
                                onChange={handleCasaOnClick}
                            />
                            {casaLegislativa && casaLegislativa !== ''
                                ?
                                <ListFilterInputItem
                                    label="Âmbito"
                                    currentFilter={props.filter}
                                    filterKey="ambito"
                                    itens={ambitos}
                                    onChange={handleGenericOnClick}
                                />
                                :
                                null}

                            {props.idPermissao != 4 ?
                                <ListFilterInputItem
                                    label="Momentos Críticos"
                                    currentFilter={props.filter}
                                    filterKey="momento"
                                    itens={momentoItens}
                                    onChange={handleMomentoOnClick}
                                />
                                :
                                null}
                        </>
                    )}

                    {check("esfera", "Municipal") && (
                        getConfig()?.habPropFiltroMult
                            ?
                            <MultipleListFilterInputItemMunicipio
                                label="Municipio"
                                currentFilter={props.filter}
                                filterKey="municipio"
                                itens={municipioNew}
                                onChange={handleMunicipioOnClickNew}
                            />
                            :
                            <ListFilterInputItem
                                label="Municipio"
                                currentFilter={props.filter}
                                filterKey="municipio"
                                itens={municipio}
                                onChange={handleMunicipioOnClick}
                            />
                    )}
                </FilterItemPanel>


                {/* Proposição */}
                <FilterItemPanel
                    panel="panel5"
                    name="proposicao"
                    label="Proposição"
                    // expanded={expanded}
                    onOpen={handleChangeOpen}
                >
                    <TitleLabel>Informações Gerais</TitleLabel>

                    {getConfig()?.habPropFiltroMult
                        ?
                        <MultipleListFilterInputItem
                            label="Tipo"
                            currentFilter={props.filter}
                            filterKey="tipo"
                            itens={tipo}
                            onChange={handleGenericOnClick}
                        />
                        :
                        <ListFilterInputItem
                            label="Tipo"
                            currentFilter={props.filter}
                            filterKey="tipo"
                            itens={tipo}
                            onChange={handleGenericOnClick}
                        />}


                    <ListFilterTextItem
                        label="Número"
                        currentFilter={props.filter}
                        filterKey="numero"
                        onChange={handleGenericOnClick}
                    />
                    <ListFilterTextItem
                        label="Ano"
                        currentFilter={props.filter}
                        filterKey="ano"
                        onChange={handleGenericOnClick}
                    />
                    <ListFilterTextItem
                        label="Apelido"
                        currentFilter={props.filter}
                        filterKey="apelido"
                        onChange={handleGenericOnClick}
                    />
                    {/* <Grid style={{ backgroundColor: pallet.backgroundColorQuintenary, width: '29rem', padding: '0px 0px 0px 3px', marginBottom: '8px', borderRadius: '5px' }}>
                        <Grid>
                            <FormControl style={{ padding: '15px 10px 5px 10px', width: '99.5%' }}>
                                <Select
                                    fullWidth
                                    variant='outlined'
                                    id="date-select"
                                    value={valueRadioDate}
                                    onChange={handleChangeDateApresEAtua}
                                    size="medium"
                                    style={{ width: '100%', backgroundColor: 'transparent', paddingTop: '3px', marginBottom: '-3px' }}

                                >
                                    <MenuItem value="apresentacao">
                                        <span style={{
                                            fontSize: pallet.general.size16,
                                            fontWeight: pallet.general.weight_300
                                        }}>
                                            Data de Apresentação
                                        </span>
                                    </MenuItem>
                                    <MenuItem value="atualizacao">
                                        <span style={{
                                            fontSize: pallet.general.size16,
                                            fontWeight: pallet.general.weight_300
                                        }}>
                                            Data de Carga (Data Atualização)
                                        </span>
                                    </MenuItem>
                                    <MenuItem value="tramitacao">
                                        <span style={{
                                            fontSize: pallet.general.size16,
                                            fontWeight: pallet.general.weight_300
                                        }}>
                                            Data da Última Tramitação
                                        </span>
                                    </MenuItem>
                                    <MenuItem value="cargaTramitacao">
                                        <span style={{
                                            fontSize: pallet.general.size16,
                                            fontWeight: pallet.general.weight_300
                                        }}>
                                            Data de Carga da Última Tramitação
                                        </span>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '10px' }}>
                            {valueRadioDate === 'apresentacao'
                                ?
                                <ListFilterRangeDatePickerItem
                                    backgroundColor={false}
                                    clear={valueRadioDate}
                                    label="Data Apresentação"
                                    currentFilter={props.filter}
                                    filterKey="dataApresentacao"
                                    onChange={handleDataApresentacaoOnClick}
                                />
                                :
                                null}
                            {valueRadioDate === 'tramitacao'
                                ?
                                <ListFilterRangeDatePickerItem
                                    backgroundColor={false}
                                    clear={valueRadioDate}
                                    label="Última Tramitação"
                                    currentFilter={props.filter}
                                    filterKey="dataTramitacao"
                                    onChange={handleDataTramitacaoOnClick}
                                />
                                :
                                null}
                            {valueRadioDate === 'atualizacao'
                                ?
                                <ListFilterRangeDatePickerItem
                                    backgroundColor={false}
                                    clear={valueRadioDate}
                                    label="Data Atualização"
                                    currentFilter={props.filter}
                                    filterKey="dataAtualizacao"
                                    onChange={handleDataAtualizacaoOnClick}
                                    keyIni={'dataAtualizacaoIni'}
                                    keyFin={'dataAtualizacaoFin'}
                                />
                                :
                                null
                            }
                            {valueRadioDate === 'cargaTramitacao'
                                ?
                                <ListFilterRangeDatePickerItem
                                    backgroundColor={false}
                                    clear={valueRadioDate}
                                    label="Carga Última Tramitação"
                                    currentFilter={props.filter}
                                    filterKey="dataCargaTramitacao"
                                    onChange={handleCargaTramitacaoOnClick}
                                    keyIni={'dataCargaTramitacaoIni'}
                                    keyFin={'dataCargaTramitacaoFin'}
                                />
                                :
                                null
                            }
                        </Grid>
                    </Grid> */}

                    <ListFilterInputItem
                        label="Tema Legislativo"
                        currentFilter={props.filter}
                        filterKey="tema"
                        itens={tema}
                        onChange={handleTemaOnClick}
                    />

                    <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}>
                        <Autocomplete
                            id='autor-select-input'
                            sx={{ width: "100%" }}
                            open={openAutor}
                            onOpen={() => setOpenAutor(true)}
                            onClose={() => setOpenAutor(false)}
                            value={autorValueChange(autorParcial)}
                            inputValue={autorParcial}
                            options={autorLista}
                            noOptionsText={"Lista de Autor vazia"}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    placeholder="Nome do Autor + <Enter>"
                                    label={"Autor"}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAutor ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.label,
                                        },
                                    }}
                                    style={{ width: '95%', backgroundColor: 'white' }}
                                />
                            )}
                            onChange={handleAutorChange}
                            onKeyPress={handleAutorKeyPress}
                        />
                    </Grid>

                    <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}>
                        <Autocomplete
                            id='outlined-select-pRelator'
                            sx={{ width: "100%" }}
                            open={openRelator}
                            onOpen={() => { setOpenRelator(true) }}
                            onClose={() => { setOpenRelator(false) }}
                            value={relatorValueChange(relatorParcial)}
                            inputValue={relatorParcial}
                            options={autorListaRelator}
                            noOptionsText={"Lista de Relator vazia"}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    placeholder="Nome do Relator + <Enter>"
                                    label={"Relator"}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingRelator ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.label,
                                        },
                                    }}
                                    style={{ width: '95%', backgroundColor: 'white' }}
                                />
                            )}
                            onChange={handleRelatorChange}
                            onKeyPress={handleRelatorKeyPress}
                        />
                    </Grid>

                    {props.idPermissao != 4 ?
                        <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}>
                            <Autocomplete
                                id='outlined-select-pRelator'
                                sx={{ width: "100%" }}
                                open={openInfluenciado}
                                onOpen={() => { setOpenInfluenciado(true) }}
                                onClose={() => { setOpenInfluenciado(false) }}
                                value={influenciadoValueChange(influenciadoParcial)}
                                inputValue={influenciadoParcial}
                                options={autorListaInfluenciado}
                                noOptionsText={"Lista de Influenciados vazia"}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        placeholder="Nome do Influenciado + <Enter>"
                                        label={"Influenciado"}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingRelator ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.label,
                                                focused: classes.label,
                                            },
                                        }}
                                        style={{ width: '95%', backgroundColor: 'white' }}
                                    />
                                )}
                                onChange={handleInfluenciadoChange}
                                onKeyPress={handleInfluenciadoKeyPress}
                            />
                        </Grid>
                        :
                        null
                    }

                    {props.idPermissao !== 4 && getConfig()?.habPropFiltroMult ?
                        <MultipleListFilterInputItem
                            label="Nível de Prioridade"
                            currentFilter={props.filter}
                            filterKey="prioridade"
                            itens={prioridadeItens}
                            onChange={handleGenericOnClick}
                        />
                        :
                        <ListFilterInputItem
                            label="Nível de Prioridade"
                            currentFilter={props.filter}
                            filterKey="prioridade"
                            itens={prioridadeItens}
                            onChange={handlePrioridadeOnClick}
                        />
                    }
                    {props.idPermissao !== 4 && getConfig()?.habPropFiltroMult ?
                        <MultipleListFilterInputItem
                            label="Posicionamento"
                            currentFilter={props.filter}
                            filterKey="posicionamento"
                            itens={posicionamentoItens}
                            onChange={handleGenericOnClick}
                        />
                        :
                        <ListFilterInputItem
                            label="Posicionamento"
                            currentFilter={props.filter}
                            filterKey="posicionamento"
                            itens={posicionamentoItens}
                            onChange={handlePosicionamentoOnClick}
                        />
                    }
                    {props.idPermissao !== 4 && getConfig()?.habPropFiltroMult ?
                        <MultipleListFilterInputItem
                            label="resultado"
                            currentFilter={props.filter}
                            filterKey="resultado"
                            itens={resultadoItens}
                            onChange={handleGenericOnClick}
                        />
                        :
                        null
                    }
                    {props.idPermissao !== 4 && getConfig()?.habPropFiltroMult ?
                        <MultipleListFilterInputItem
                            label="Status do Resultado"
                            currentFilter={props.filter}
                            filterKey="resultado_status"
                            itens={resultadoStatusItens}
                            onChange={handleGenericOnClick}
                        />
                        :
                        null
                    }
                    {props.idPermissao !== 4 && getConfig()?.habPropFiltroMult ?
                        <>
                            <MultipleListFilterInputItem
                                label="Ação de Acontecimento"
                                currentFilter={props.filter}
                                filterKey="acao_acontecimento"
                                itens={listaAcao}
                                onChange={handleGenericOnClick}
                            />

                            <ListFilterRangeDatePickerItem
                                label="Período de Ação"
                                currentFilter={props.filter}
                                filterKey="periodoAtividade"
                                onChange={handleDataPeriodoAtividadeOnClick}
                                keyIni={'periodoAtividadeIni'}
                                keyFin={'periodoAtividadeFin'}
                            />
                        </>

                        :
                        null
                    }
                    {props.idPermissao != 4 ?
                        <TitleLabel>Classificação de Temas Corporativos</TitleLabel>

                        :
                        null
                    }

                    {/* GRUPO ___________________ */}
                    {/* <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}> */}
                    {/* <TextField
                            id={`outlined-select-grupo`}
                            select
                            label={"Grupo"}
                            value={grupoSelected}
                            onChange={(event) => handleGrupoOnClick(event, event.target.value)}
                            style={{ width: '95%', backgroundColor: 'white' }}
                        >
                            {grupo.map((option) => {
                                return (
                                    <MenuItem
                                        value={option}
                                        style={{
                                            color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                            fontFamily: 'Montserrat',
                                            fontStyle: 'normal',
                                            fontWeight: 'normal',
                                            fontSize: '16px'
                                        }}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField> */}


                    {props.idPermissao != 4 ?

                        <ListFilterInputItem
                            label="Grupo"
                            currentFilter={props.filter}
                            filterKey="grupos"
                            itens={grupo}
                            onChange={handleGrupoOnClick}
                        />
                        :
                        null
                    }
                    {listTemaByGrupo.length > 0
                        ?
                        <ListFilterInputItem
                            label="Tema"
                            currentFilter={props.filter}
                            filterKey="temas"
                            itens={listTemaByGrupo}
                            onChange={handleTemaByGrupoOnClick}
                        />
                        :
                        msgNoTema
                            ?
                            <Grid style={{
                                color: "red",
                                fontSize: '10px',
                                paddingLeft: '20px',
                                paddingBottom: '20px'
                            }}>
                                Não existe tema para este grupo
                            </Grid>
                            :
                            null}
                    {listSubtemaByTema.length > 0
                        ?
                        <ListFilterInputItem
                            label="Subtema"
                            currentFilter={props.filter}
                            filterKey="subtemas"
                            itens={listSubtemaByTema}
                            onChange={handleSubtemaByTemaOnClick}
                        />
                        :
                        msgNoSubtema && !msgNoTema
                            ?
                            <Grid style={{
                                color: "red",
                                fontSize: '10px',
                                paddingLeft: '20px',
                                paddingBottom: '20px'
                            }}>
                                Não existe subtema para este tema
                            </Grid>
                            :
                            null}
                    {listTopicoBySubtema.length > 0
                        ?
                        <ListFilterInputItem
                            label="Tópico"
                            currentFilter={props.filter}
                            filterKey="topicos"
                            itens={listTopicoBySubtema}
                            onChange={handleTopicoBySubtemaOnClick}
                        />
                        :
                        msgNoSubtema && !msgNoTema
                            ?
                            <Grid style={{
                                color: "red",
                                fontSize: '10px',
                                paddingLeft: '20px',
                                paddingBottom: '20px'
                            }}>
                                Não existe Topico para este tema
                            </Grid>
                            :
                            null}

                    {/* </Grid> */}

                    {/* {listTemaByGrupo.length > 0
                        ?
                        <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}>
                            <TextField
                                id={`outlined-select-tema`}
                                select
                                label={"Tema"}
                                value={temaSelected}
                                onChange={(event) => handleTemaByGrupoOnClick(event, event.target.value)}
                                style={{ width: '95%', backgroundColor: 'white' }}
                            >
                                {listTemaByGrupo.map((option) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            style={{
                                                color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal',
                                                fontWeight: 'normal',
                                                fontSize: '16px'
                                            }}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        :
                        msgNoTema
                            ?
                            <Grid style={{
                                color: "red",
                                fontSize: '10px',
                                paddingLeft: '20px',
                                paddingBottom: '20px'
                            }}>
                                Não existe tema para este grupo
                            </Grid>
                            :
                            null} */}



                    {/* {listSubtemaByTema.length > 0
                        ?
                        <Grid style={{ width: "100%", paddingLeft: "10px", paddingBottom: "17px", paddingTop: "6px" }}>
                            <TextField
                                id={`outlined-select-subtema`}
                                select
                                label={"subtema"}
                                value={subtemaSelected}
                                onChange={(event) => handleSubtemaByTemaOnClick(event, event.target.value)}
                                style={{ width: '95%', backgroundColor: 'white' }}
                            >
                                {listSubtemaByTema.map((option) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            style={{
                                                color: option.key === '' ? '#9B9B9B' : '#1E0736',
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal',
                                                fontWeight: 'normal',
                                                fontSize: '16px'
                                            }}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        :
                        msgNoSubtema
                            ?
                            <Grid style={{
                                color: "red",
                                fontSize: '10px',
                                paddingLeft: '20px',
                                paddingBottom: '20px'
                            }}>
                                Não existe subtema para este tema
                            </Grid>
                            :
                            null} */}
                </FilterItemPanel>
            </FiltroDiv>
        </>
    );
}
