import React, { useState, useCallback, SyntheticEvent } from 'react';
import { styled, alpha } from '@mui/material/styles';
import sty from "styled-components";
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Divider, Button, Autocomplete, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Box, Pagination, Stack } from '@mui/material';
import { usePallet } from '../../../../contexts/PalletContext';
import useStyles from '../../../../components/layout/Styles';
import ProposicaoAcaoInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';
import { formatDate } from '../../../../utils/dateUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputDatePicker from '../../../../components/InputDatePicker';
import InputMultilineTextField from '../../../../components/InputMultilineTextField'
import SolidButton from '../../../../components/SolidButton';
import CloseIcon from '@mui/icons-material/Close';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import { useAlert } from '../../../../contexts/AlertContext';
import UploadFilesService from '../../../../services/UploadFiles.service';
import MenuButtonIconNoText from "../../../../components/MenuButtonIconNoText";
import { MoreHoriz, Visibility } from "@material-ui/icons";
import Menu, { MenuProps } from '@mui/material/Menu';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import CircularLoading from '../../../../components/CircularLoading';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import TabelasService from '../../../../services/TabelasService';
import UsuarioService from '../../../../services/UsuarioService';
import ProposicaoAcaoInfluenciaMenuFiltroTipoResultado from './ProposicaoAcaoInfluenciaMenuFiltroTipoResultado';
import AccordionTextDataAcaoInfluenciaProposicao from '../../../proposicao/_components/acaodeinfluencia/AccordionTextDataAcaoInfluenciaProposicao';
import ProposicaoAcaoDeInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';
import EmptyErrorDoc from '../../../../components/EmptyErrorDoc';
import ProposicaoAcaoInfluenciaCreateSchema from '../../models/ProposicaoAcaoInfluenciaCreateSchema'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DeadLineDate from '../../../../components/DeadLineDate';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ParlamentarService from '../../../../services/ParlamentarService';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';


const casasLegislativas = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Câmara', label: 'Câmara dos Deputados', value: 2 },
    { key: 'Senado', label: 'Senado Federal', value: 1 },
    { key: 'Congresso', label: 'Congresso Nacional', value: 3 },
    { key: 'Assembleia', label: 'Assembléia Legislativa', value: 4 },
    { key: 'Municipais', label: 'Câmaras Municipais', value: 5 },
]

const DotPosicionamento = sty.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "25px"};
`;

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '0px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));




export interface IFileInfo {
    name: string,
    filename: string,
    size: number,
    new: boolean,
    file: any
}

export default function ProposicaoAcaoInfluencia(props: { id_proposicao: number, index: number, activeTab: number, propDescricao?: string }) {

    const { NewAlert } = useAlert();
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const { pallet } = usePallet();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [infoList, setInfoList] = useState<any>();
    const [descricao, setDescricao] = useState("");
    const [idReferencia, setIdReferencia] = React.useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [prazo, setPrazo] = useState<any>(new Date);
    const [casaSelecionada, setCasaSelecionada] = React.useState<number>(undefined);
    const [ambitos, setAmbitos] = React.useState([]);
    const [ambitoSelecionado, setAmbitoSelecionado] = React.useState();
    const [resultado, setResultado] = useState<number>();
    const [departamento, setDepartamento] = useState([]);
    const [trocaCasa, setTrocaCasa] = useState(false);
    const [lista, setLista] = useState([]);
    const [userList, setUserList] = useState([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([]);
    const [responsavel, setResponsavel] = useState();
    const [responsavelNome, setResponsavelNome] = useState('');
    const [responsaveisList, setResponsaveisList] = useState([{ id: "", label: "", value: "" }]);
    const [influenciaSobre, setInfluenciaSobre] = useState<Array<number>>([]);
    const [influenciaSobreOptions, setInfluenciaSobreOptions] = useState([]);
    const [filtro, setFiltro] = useState<string>('T');
    const [data, setData] = useState({});
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);
    const [expandedAcordion, setExpandedAcordion] = useState(false);
    const { user } = useAuthorization();

    // - - - - - - LIMPA CAMPOS FORM - - - - - -
    React.useEffect(() => {
        if (!idReferencia && props.id_proposicao) {
            setPrazo(undefined)
            setCasaSelecionada(undefined)
            setResponsavel(undefined)
            setResponsavelNome(undefined)
            setAmbitoSelecionado(undefined)
            setResultado(undefined)
            setInfluenciaSobre([])
            setDescricao('')
            getList();
        }
    }, [idReferencia])

    React.useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, filtro])

    const loadingListAfterSalvar = () => {
        setLoadingAfterSalvar(true)
        getList()
        setTimeout(() => {
            setLoadingAfterSalvar(false)
        }, 3000);
    }

    const getList = useCallback(async () => {
        setLoading(true);
        const f = [{ key: 'status', value: filtro }]

        if (currentPage && currentPage > 0)
            await ProposicaoAcaoInfluenciaService.GetList(props.id_proposicao, f, currentPage, 8).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);

                }
                setLoading(false);
            })

    }, [props.id_proposicao, currentPage, filtro])

    const setPage = (event: object, page: number) => setCurrentPage(page);
    const setFilter = (f: any) => setFiltro(f.target.value);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }



    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    React.useEffect(() => {
        UsuarioService.GetList().then(async res => {
            const responsaveis = res?.map(usuario => {
                return {
                    id: usuario.id,
                    name: usuario.nome + (" - ") + truncate(usuario.departamento.nome, 40),
                    value: usuario.id,
                }
            })
            setResponsaveisList(responsaveis);
        },
        )
    }, []);

    const corResultado = (value: string) => {
        switch (value) {
            case "semExito":
                return `${pallet.charts.red}`;
            case "pendente":
                return `${pallet.charts.yellow}`;
            case "exitoParcial":
                return `${pallet.charts.blue}`;
            case "exito":
                return `${pallet.charts.green}`;
            default:
                return 'white';
        }
    };


    function LabelHandleResultado(props: {
        resultado: string;
        bg: string;
    }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corResultado(`${props?.bg}`)} />
                    </Grid>
                    <Grid item>{props?.resultado}</Grid>
                </Grid>
            </>
        );
    }


    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }


    const resultadoItens = [
        {
            key: 1,
            label: (
                <LabelHandleResultado
                    resultado={"Pendente"}
                    bg={"pendente"}
                />
            ),
            value: 4,
        },
        {
            key: 2,
            label: (
                <LabelHandleResultado
                    resultado={"Sem Êxito"}
                    bg={"semExito"}
                />
            ),
            value: 2,
        },
        {
            key: 3,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito Parcial"}
                    bg={"exitoParcial"}
                />
            ),
            value: 3,
        },
        {
            key: 4,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito"}
                    bg={"exito"}
                />
            ),
            value: 1,
        },
    ];

    // --- handleOnAfterUpload precisa ter sempre! Está relacionado à eventos posteriores ao upload de anexos (nao apagar) ---
    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
        }
        loadingListAfterSalvar();
    }

    const salvarAcaoInfluencia = async (e) => {
        setLoading(true)
        e.preventDefault();
        const data = {
            prazo: !prazo ? new Date : prazo,
            resultado: resultado,
            responsavelId: responsavel,
            influenciaSobre: influenciaSobre,
            casa: casaSelecionada,
            ambito: ambitoSelecionado,
            descricao: descricao,
            propDescricao: props?.propDescricao,
        }
        setError(() => [])
        data && ProposicaoAcaoInfluenciaCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoAcaoInfluenciaCreateSchema.validate(data).catch(function (err) {
        });

        if ((ProposicaoAcaoInfluenciaCreateSchema.isValidSync(data)) || (casaSelecionada === 4 && ambitoSelecionado === undefined) || (casaSelecionada === 5 && ambitoSelecionado === undefined)) {

            await ProposicaoAcaoDeInfluenciaService.Create(data, props?.id_proposicao)
                .then(res => {
                    let idPropAcaoInfluencia = res
                    if (res >= 0) {
                        setIdReferencia(idPropAcaoInfluencia)
                        NewAlert('success', 'Ação de Influência cadastrada com sucesso')
                        setLoading(true)
                        setLoadingForm(true)
                        setTimeout(() => {
                            setLoadingForm(false)
                            setLoading(false)
                        }, 500);

                    } else {
                        NewAlert('error', 'Erro ao cadastrar, verifique os campos e salve novamente');
                        setTimeout(() => {
                            setLoadingForm(false)
                            setLoading(false)
                        }, 500);
                    }
                }).then(x => {
                    setIdReferencia(undefined)
                    loadingListAfterSalvar()
                })
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
            setTimeout(() => {
                setLoadingForm(false)
                setLoading(false)
            }, 500);
        }


    }


    // ------------------- CHANGE DATA AREA ------------------- `

    const handlePrazoChange = (prazo) => {
        setPrazo(prazo)
    };

    const handleCasaLegislativaChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmbitos([])
        setAmbitoSelecionado(undefined)
        setTrocaCasa(true)
        setInfluenciaSobre([])
        setInfluenciaSobreOptions([])
        setCasaSelecionada(parseInt(event.target.value))
        TabelasService.getTabelaAmbitosTipo(Number(event.target.value)).then(async (res) => {
            const data = [];
            data.push({ key: "", label: "-- Selecione -- ", value: "" });
            res?.map((item) =>
                data.push({
                    key: item.id,
                    label: item.sigla + " - " + item.resumo,
                    value: item.id,
                })
            );
            setAmbitos(() => [...data]);

        }).then(() => setTrocaCasa(false))
    };

    const handleResponsavelChange = (event) => {
        setResponsavel(event.target.value)
        setResponsavelNome(event.target.name)
    };

    const handleAmbitoChange = (event) => {
        setAmbitoSelecionado(event.target.value)
    }

    const handleResultadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResultado(Number(event.target.value))
    };

    const handleInfluenciaSobreChange = (event, values) => {
        setInfluenciaSobre(values.map((i) => { return (i.value) }));
    }


    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    const handleChangeACNewList = () => {
        setLoading(true);
        getList();
    };

    const filterOptions = (event) => {
        if (event.target.value && event.target.value.trim().length > 1) {
            ParlamentarService.GetPartialNameWithId(
                event.target.value, 
                casaSelecionada === 1 ? [{ key: 'casa', value: '1' }] : 
                casaSelecionada === 2 ? [{ key: 'casa', value: '2' }] :
                casaSelecionada === 3 ? [{ key: 'casa', value: '1' }, { key: 'casa', value: '2' }] :
                casaSelecionada === 4 ? [{ key: 'esfera', value: 'Estadual'}] :
                casaSelecionada === 5 ? [{ key: 'esfera', value: 'Municipal'}] :
                []
                ).then(async res => setInfluenciaSobreOptions(res ? res : null));
        }
    };

    const handleChangeAcordion = (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAcordion(!ativo)
    }

    return (
        <>
            {/* ---------------------------- FORM ACAO DE INFLUENCIA ------------------------------ */}
            {/* <CustomizedDialogs /> */}
            <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>
                <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "20px" : "45px"}`, paddingTop: `${isMobile ? "0px" : "30px"}` }}>
                    {loadingForm ?
                        <CircularLoading
                            size={80}
                            top={"50%"}
                            left={"50%"}
                            zIndex={2}
                        />
                        :
                        <Grid>
                            <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} onChange={handleChangeAcordion(expandedAcordion)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: `${pallet.color.primary.font}` }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{
                                        backgroundColor: `${pallet.color.primary.background}`,
                                        borderRadius: "5px",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        flexFlow: "row-reverse",
                                        height: "64px"
                                    }}
                                >
                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.color.primary.font}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontSize: `${pallet.fontSize.titleWidgets}`,
                                        fontWeight: pallet.fontWeight.subtitle,
                                        marginLeft: "10px"
                                    }}>
                                        NOVA AÇÃO DE INFLUÊNCIA
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails style={{
                                    fontFamily: `${pallet.fontFamily.general}`
                                }}>
                                    <Grid container spacing={4} style={{ padding: "25px 20px 20px 20px" }}>
                                        <Grid item lg={6} xs={12}>
                                            <InputDatePicker
                                                onChange={handlePrazoChange}
                                                label={"PRAZO"}
                                                inputFormat={"dd/MM/yyyy"}
                                                value={prazo}
                                                id={"casa-acaoInfluencia"}
                                                
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='prazo' errors={error} />
                                            </Grid>

                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <SelectTextFieldA
                                                options={resultadoItens}
                                                // placeholder={"Selecione departamento ou usuário"}
                                                value={resultado}
                                                onChange={handleResultadoChange}
                                                label={"RESULTADO"}
                                                id={"resultado-acaoInfluencia"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='resultado' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <SelectTextFieldA
                                                value={responsavel}
                                                onChange={handleResponsavelChange}
                                                options={responsaveisList}
                                                label={"RESPONSÁVEL"}
                                                id={"responsavel-acaoInfluencia"}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='responsavel' errors={error} />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <SelectTextFieldA
                                                value={casaSelecionada}
                                                options={casasLegislativas}
                                                label={"CASA LEGISLATIVA"}
                                                id={"casa-acaoInfluencia"}
                                                onChange={handleCasaLegislativaChange}
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='casa' errors={error} />
                                            </Grid>
                                        </Grid>

                                        {casaSelecionada && trocaCasa === false
                                            ?
                                            <Grid item lg={6} xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={influenciaSobreOptions}
                                                    getOptionLabel={(option) => (option.nome ? option?.nome + (' - ') + option?.sigla_partido + (' - ') + option?.tratamento : option.label)}
                                                    onInputChange={filterOptions}
                                                    onChange={handleInfluenciaSobreChange}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            fullWidth
                                                            label="INFLUÊNCIA SOBRE"
                                                            placeholder="Busque o interlocutor"
                                                            variant='outlined'
                                                            {...params}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "grey"
                                                                },
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    notchedOutline: classes.notchedOutline
                                                                },
                                                                style: {
                                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                                    fontSize: `${pallet.general.size14}`,
                                                                    fontWeight: pallet.general.weight_100,
                                                                    background: `${pallet.backgroundColorSecondary}`
                                                                },
                                                                ...params.InputProps,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='influenciaSobre' errors={error} />
                                                </Grid>
                                            </Grid>
                                            :
                                            null}

                                        {casaSelecionada && casaSelecionada !== 4 && casaSelecionada !== 5 && casaSelecionada !== 0 ?
                                            <Grid item lg={12} xs={12}>
                                                <SelectTextFieldA
                                                    value={ambitoSelecionado}
                                                    onChange={handleAmbitoChange}
                                                    options={ambitos}
                                                    label={"ÂMBITOS"}
                                                    id={"ambitos-acaoInfluencia"}
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='ambito' errors={error} />
                                                </Grid>
                                            </Grid>
                                            :
                                            null
                                        }

                                        <Grid item lg={12} xs={12}>
                                            <InputMultilineTextField
                                                type={"text"}
                                                maxRows={4}
                                                value={descricao}
                                                defaultValue={descricao}
                                                onChange={handleDescricaoChange}
                                                id={"descricao-proposicao"}
                                                label="DESCRIÇÃO"
                                            />
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                <ErrorMessage name='descricao' errors={error} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>



                                <Grid style={{ paddingBottom: "10px" }}>
                                    <Divider />
                                </Grid>

                                <Grid style={{ padding: "20px 18px 20px 18px" }}>
                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                        <Grid style={{
                                            fontWeight: pallet.fontWeight.subtitle,
                                            fontSize: `${pallet.general.size12}`,
                                            color: `${pallet.color.tertiary.font}`,
                                            marginLeft: "9px"
                                        }}>
                                            ANEXOS
                                        </Grid>

                                        <Grid className="container">
                                            <AnexosComponent
                                                label=''
                                                modulo={EAnexoModulos.ProposicaoAcaoInfluencia}
                                                id_referencia={idReferencia}
                                                canEdit={true}
                                                autosave={true}
                                                onAfterUpload={handleOnAfterUpload}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Divider />
                                </Grid>
                                <Grid container justifyContent="flex-end"
                                    style={{
                                        backgroundColor: `${pallet.backgroundColorTertiary}`,
                                        padding: "35px 30px 35px 30px"
                                    }}>

                                    <Grid>
                                        <SolidButton
                                            onClick={user.id_permissao !== 3 ? salvarAcaoInfluencia : erroAlteracaoPerfil}
                                            color={`${pallet.textColorPrimary}`}
                                            backgroundColor={`${pallet.backgroundColorPrimary}`}
                                            fontSize={`${pallet.general.size14}`}
                                            title={"SALVAR"}
                                        />
                                    </Grid>
                                </Grid>
                            </Accordion>
                        </Grid>
                    }
                </Grid>

                {infoList?.totalItens === undefined || (infoList?.totalItens <= 0 && infoList?.totalNoFilter === 0)
                    ?
                    !expandedAcordion &&
                    <Grid
                        style={{
                            color: `${pallet.textColorQuaternary}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "100px",
                            marginTop: "20px",
                        }}
                    >
                        <EmptyErrorDoc title="Comece criando uma nova ação influência"
                            description="Você ainda não criou nenhuma ação influência para esta proposição."
                            subdescription="" />
                    </Grid>
                    :
                    null}

                {infoList?.totalNoFilter > 0
                    ?
                    <Grid>
                        <Grid>
                            <Divider />
                        </Grid>


                        {/* ---------------------------- FILTRO E CONTADOR ------------------------------ */}


                        <Grid style={{ padding: `${isMobile ? "5px 5px 0px 15px" : "0px 0px 20px 0px"}` }}>
                            {loading ?
                                <CircularLoading
                                    size={80}
                                    top={"50%"}
                                    left={"50%"}
                                    zIndex={2}
                                />
                                :
                                <Grid container item alignItems="flex-start" style={{
                                    paddingLeft: `${isMobile ? "10px" : "30px"}`,
                                    paddingRight: `${isMobile ? "10px" : "30px"}`,
                                    paddingTop: `${isMobile ? "10px" : "30px"}`,
                                    marginBottom: `${isMobile ? "-10px" : "10px"}`
                                }} >
                                    <Grid container item alignItems="baseline" xs={12} >
                                        <Grid xs={6} container item alignItems="baseline">
                                            <Grid item style={{
                                                marginRight: `${isMobile ? "8px" : "8px"}`,
                                                paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                            }}>
                                                <Grid item style={{
                                                    fontSize: "18px",
                                                    color: `${pallet.textColorTertiary}`,
                                                    fontWeight: pallet.general.weight_600
                                                }}>
                                                    {infoList?.totalItens}
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Grid item style={{
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: "14px",
                                                    fontWeight: pallet.general.weight_600,
                                                    paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                }}>
                                                    {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"}
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} container item justifyContent="flex-end" >
                                            <Grid item>
                                                <Grid style={{ paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}`, paddingLeft: `${isMobile ? "0" : "0px"}` }}>
                                                    <ProposicaoAcaoInfluenciaMenuFiltroTipoResultado title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    {/* ---------------------------- LISTA  ------------------------------ */}

                                    <Grid style={{
                                        width: "100%",
                                        paddingLeft: `${isMobile ? "0px" : "20px"}`,
                                        paddingRight: `${isMobile ? "5px" : "20px"}`,
                                        paddingTop: "20px",
                                        paddingBottom: `${isMobile ? "20px" : "40px"}`
                                    }}
                                    >
                                        {loading === true ?
                                            <CircularLoading
                                                size={80}
                                                top={"50%"}
                                                left={"50%"}
                                                zIndex={2}
                                            />
                                            :
                                            loadingAfterSalvar === true ?
                                                <CircularLoading
                                                    size={80}
                                                    top={"50%"}
                                                    left={"50%"}
                                                    zIndex={2}
                                                />
                                                :
                                                <AccordionTextDataAcaoInfluenciaProposicao
                                                    lista={lista}
                                                    onChange={handleChangeACNewList}
                                                    propDescricao={props?.propDescricao}
                                                />

                                        }
                                    </Grid>
                                </Grid>
                            }


                            <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "40px" : "0px"}` }}>

                                <Stack spacing={2}>
                                    <Pagination
                                        size={isMobile ? "small" : "large"}
                                        onChange={setPage}
                                        count={infoList?.totalPages}
                                        page={currentPage}
                                        shape="rounded"
                                    />
                                </Stack>

                            </Grid>

                        </Grid>
                    </Grid>
                    :
                    null
                }

            </div>

        </>
    )
}
