import * as React from 'react';

import { Grid, Link } from '@mui/material';
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from 'react-device-detect';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/BootstrapDialogTitle';


export default function ProposicaoConsultaInformacoesModalEspecifica(props: { proposicao_id: number, proposicao_descricao: string, texto_ementa: string, ementa: string }) {
    const { pallet } = usePallet();
    const [open, setOpen] = React.useState(false);

    const [ementa, setEmenta] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div>
            {props?.texto_ementa ?
                <Link href="#" onClick={handleClickOpen} underline="none">
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        style={{
                            fontSize: pallet.general.size14,
                            marginLeft: "20px",
                            marginTop: "-2px",
                            wordBreak: "break-word",
                            fontWeight: pallet.general.weight_300,
                            color: 'black'
                        }}
                    >
                        {props?.ementa}
                    </Grid>
                </Link>
                :
                <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{
                        fontSize: pallet.general.size14,
                        marginLeft: "20px",
                        marginTop: "5px",
                        wordBreak: "break-word",
                        fontWeight: pallet.general.weight_300
                    }}
                >
                    {props?.ementa}
                </Grid>}

            <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.proposicao_descricao}
                </BootstrapDialogTitle>

                <Grid style={{ padding: "30px" }}>
                    {props.texto_ementa}
                </Grid>



            </BootstrapDialog>
        </div >
    );
}
