import * as React from "react";

import {
  Grid,
  Link,
  TextField,
  Typography,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useAlert } from "../../../../contexts/AlertContext";
import useStyles from "../../../../components/layout/Styles";
import ParlamentarDemandaService from "../../../../services/ParlamentarDemandaService";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import { CalendarToday } from "@material-ui/icons";
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import InputTextField from "../../../../components/InputTextField";
import {
  ICheckBoxNestedItem,
  InputDropDownCheckBoxNested,
} from "../../../../components/CheckBoxNested";
import SolidButton from "../../../../components/SolidButton";
import styled from "styled-components";
import UsuarioService from "../../../../services/UsuarioService";
import { arraysEquals } from "../../../../utils/array.utils";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import FormGroup from "@mui/material/FormGroup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ProposicaoAcaoPreparatoriaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import ImpactoFinanceiroData from "../../../dashboard/_components/ImpactoFinandeiroData";
import { formatDate } from "../../../../utils/dateUtils";
import ButtonIconLeft from "../../../../components/ButtonIconLeft";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../components/BootstrapDialogTitle";
import { INotaTecnica } from "../../../../models/Proposicao.Model";
import FeedIcon from "@mui/icons-material/Feed";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import { useNavigate } from "react-router-dom";
import CircularLoading from '../../../../components/CircularLoading';



const DotPosicionamento = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "25px"};
`;

const GridColumn = styled(Grid)`
  padding: ${isMobile ? "8px 4px 8px 4px" : "15px"};
  overflow-wrap: "break-word";
`;

export default function NotaTecnicaModal(props: {
  onChange: (data: any) => void;
  closeMenu?: () => void;
  reload?: () => void;
  children?: React.ReactNode;
  nota_tecnica: INotaTecnica;
  acao_preparatoria: IAcaoPreparatoria;
  responsaveis: Array<number>;
  editLayout?: boolean;
  isNewStatusAnexo?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const { NewAlert } = useAlert();

  const [idReferencia, setIdReferencia] = React.useState<number>();
  const [posicionamento, setPosicionamento] = useState<string>();
  const [prioridade, setPrioridade] = useState<string>();
  const [tipoImpacto, setTipoImpacto] = useState<string>();
  const [impacto, setImpacto] = useState<string>();
  const [titleDialog, setTitleDialog] = useState<string>(
    "QUALIFICAR NOTA TÉCNICA"
  );
  const [justificativa, setJustificativa] = useState<string>();
  const [departamento, setDepartamento] =
    useState<Array<ICheckBoxNestedItem>>();
  const [userList, setUserList] = useState<Array<number>>([]);
  const [responsavel, setResponsavel] = useState<string>();
  const [confirmacao, setConfirmacao] = useState<boolean>(false);
  const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);
  const [dataRecebimento, setDataRecebimento] = useState(Date);
  const [status, setStatus] = useState<string>();
  const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
  const [open, setOpen] = React.useState(false);
  const [newUserList, setNewUserList] = useState<Array<number>>([]);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    SaveValidation();
  }, [
    posicionamento,
    newUserList,
    tipoImpacto,
    justificativa,
    confirmacao,
    prioridade,
  ]);

  function SaveValidation() {
    if (prioridade === "Máxima" && confirmacao === true) {
      setLockSaveButton(false);
    } else if (
      posicionamento &&
      tipoImpacto &&
      justificativa &&
      prioridade != "Máxima"
    ) {
      setLockSaveButton(false);
    } else if (newUserList.length > 0 && justificativa) {
      setLockSaveButton(false);
    } else setLockSaveButton(true);
  }

  useEffect(() => {
    if (open) {
      UsuarioService.GetByDpto().then(async (res) => {
        const comboItens = res.map((departamento) => {
          return {
            id: departamento.id,
            label: departamento.nome,
            items: departamento.usuarios.map((usuario) => {
              return {
                id: usuario.id,
                label: usuario.nome,
                value: false,
              };
            }),
          };
        });
        setDepartamento(comboItens);

        setUserList(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        setLockedUserList(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        // setNewUserList

        if (props.editLayout === true) {
          setTitleDialog("EDITAR NOTA TÉCNICA");
        }
      });

      if (props.nota_tecnica.status === "Preenchido") {
        setTipoImpacto(String(props.nota_tecnica.tipo_impacto));
        setPosicionamento(String(props.nota_tecnica.posicionamento));
        setImpacto(String(props.nota_tecnica.valor_impacto));
        setResponsavel(String(props.acao_preparatoria.responsaveis_esforco));
        setPrioridade(String(props.nota_tecnica.prioridade));

        setJustificativa(
          String(
            props.nota_tecnica.justificativa
              ? props.nota_tecnica.justificativa
              : ""
          )
        );
        setConfirmacao(Boolean(props.nota_tecnica.aprovado));
        setDataRecebimento(
          formatDate(new Date(props.nota_tecnica.recebido_em), "dd/MM/yyyy")
        );
      }
    }
  }, [open]);

  const handleSetUserList = React.useCallback(
    (data) => {
      if (!arraysEquals(userList, data.selected)) {
        setDepartamento(() => [...data.list]);
        setUserList(data.selected);
        const deleteFrom = new Set(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        const newValue = data.selected.filter((user) => {
          return !deleteFrom.has(user);
        });
        setNewUserList(newValue);
      }
    },
    [departamento]
  );

  const corPosicionamento = (value: string) => {
    switch (value) {
      case "Contrário":
          return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
          return `${pallet.charts.orange}`;
      case "Neutro":
          return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
          return `${pallet.charts.blue}`;
      case "Favorável":
          return `${pallet.charts.green}`;
      case "Pendente":
          return `${pallet.charts.yellow}`;
      case "Indefinido":
          return `${pallet.charts.lightGrey}`;
       case "Favorável ao parecer do relator":
          return '#C71585';
      case "Favorável ao parecer do relator com emenda":
          return '#B8860B';
      case "Contrário ao parecer do relator":
          return '#facb74';
      case "Contrário ao parecer do relator com emenda":
          return '#000000';
      default:
          return `${pallet.charts.lightGrey}`;
  }
  };

  function LabelHandlePosicionamento(props: {
    posicionamento: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.posicionamento}</Grid>
        </Grid>
      </>
    );
  }

  const corImpacto = (value: string) => {
    switch (value) {
      case "Indiferente":
        return `${pallet.charts.lightGrey}`;
      case "Positivo":
        return `${pallet.charts.green}`;
      case "Negativo":
        return `${pallet.charts.red}`;
      case "Não Mensurável":
        return `${pallet.charts.purple}`;
      default:
        return `${pallet.charts.yellow}`;
    }
  };

  function LabelImpactoHandle(props: {
    impacto: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corImpacto(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.impacto}</Grid>
        </Grid>
      </>
    );
  }

  const corPrioridade = (value: string) => {
    switch (value) {
      case "Máxima":
        return `${pallet.charts.red}`;
      case "Baixa":
        return `${pallet.charts.blue}`;
      case "Média":
        return `${pallet.charts.yellow}`;
      case "Alta":
        return `${pallet.charts.purple}`;
      case "Mínima":
        return `${pallet.charts.green}`;
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  function LabelPrioridadeHandle(props: {
    prioridade: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPrioridade(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.prioridade}</Grid>
        </Grid>
      </>
    );
  }

  const posicionamentosItens = [
    {
      key: 0,
      label: (
        <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />
      ),
      value: "Neutro",
    },
    {
      key: 1,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário com emenda saneadora"}
          bg={"Contrário com emenda saneadora"}
        />
      ),
      value: "Contrário com emenda saneadora",
    },
    {
      key: 2,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário"}
          bg={"Contrário"}
        />
      ),
      value: "Contrário",
    },
    {
      key: 3,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável com emenda saneadora"}
          bg={"Favorável com emenda saneadora"}
        />
      ),
      value: "Favorável com emenda saneadora",
    },
    {
      key: 4,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável"}
          bg={"Favorável"}
        />
      ),
      value: "Favorável",
    },
    {
      key: 5,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável ao parecer do relator"}
          bg={"Favorável ao parecer do relator"}
        />
      ),
      value: "Favorável ao parecer do relator",
    },
    {
      key: 6,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável ao parecer do relator com emenda"}
          bg={"Favorável ao parecer do relator com emenda"}
        />
      ),
      value: "Favorável ao parecer do relator com emenda",
    },
    {
      key: 7,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário ao parecer do relator"}
          bg={"Contrário ao parecer do relator"}
        />
      ),
      value: "Contrário ao parecer do relator",
    },
    {
      key: 9,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário ao parecer do relator com emenda"}
          bg={"Contrário ao parecer do relator com emenda"}
        />
      ),
      value: "Contrário ao parecer do relator com emenda",
    },
    {
      key: 8,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Pendente"}
          bg={"Pendente"}
        />
      ),
      value: "Pendente",
    },
    
  ];

  const impactoFinanceiroItens = [
    {
      key: 0,
      label: (
        <LabelImpactoHandle impacto={"Não Mensurável"} bg={"Não Mensurável"} />
      ),
      value: "Não Mensurável",
    },
    {
      key: 1,
      label: <LabelImpactoHandle impacto={"Indiferente"} bg={"Indiferente"} />,
      value: "Indiferente",
    },
    {
      key: 2,
      label: <LabelImpactoHandle impacto={"Positivo"} bg={"Positivo"} />,
      value: "Positivo",
    },
    {
      key: 3,
      label: <LabelImpactoHandle impacto={"Negativo"} bg={"Negativo"} />,
      value: "Negativo",
    },
  ];

  const prioridadeItens = [
    {
      key: 0,
      label: <LabelPrioridadeHandle prioridade={"Máxima"} bg={"Máxima"} />,
      value: "Máxima",
    },
    {
      key: 1,
      label: <LabelPrioridadeHandle prioridade={"Baixa"} bg={"Baixa"} />,
      value: "Baixa",
    },
    {
      key: 2,
      label: <LabelPrioridadeHandle prioridade={"Média"} bg={"Média"} />,
      value: "Média",
    },
    {
      key: 3,
      label: <LabelPrioridadeHandle prioridade={"Alta"} bg={"Alta"} />,
      value: "Alta",
    },
    {
      key: 4,
      label: <LabelPrioridadeHandle prioridade={"Mínima"} bg={"Mínima"} />,
      value: "Mínima",
    },
  ];

  const handleImpactoValorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImpacto(event.target.value);
  };

  const handleJustificativaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setJustificativa(event.target.value);
  };

  const handlePosicionamentoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPosicionamento(event.target.value);
  };

  const handleImpactoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoImpacto(event.target.value);
  };

  const handlePrioridade = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrioridade(event.target.value);
  };

  const handleConfirmacaoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacao(value);
  };

  const salvarPosicionamento = () => {
    setLoading(true)
    const data = {
      impacto: tipoImpacto,
      posicionamento: posicionamento,
      impactoValor: impacto,
      prioridade: prioridade,
      justificativa: justificativa,
      responsavel: newUserList,
      confirmacao: confirmacao,
      status: "Preenchido",
      id_acao_preparatoria: props.acao_preparatoria.id,
    };

    ProposicaoAcaoPreparatoriaNotaTecnicaService.Update(
      data,
      props?.nota_tecnica.id
    )
      .then((res) => {
        console.log("CADE O RES",res)
        if (res) {
          NewAlert("success", "Nota Técnica salva com sucesso");
          setLoading(false)
          ProposicaoAcaoPreparatoriaNotaTecnicaService.GetList(
            Number(props.acao_preparatoria.id),
            0
          ).then((notaTecnica) => {
            props.onChange({ notaTecnica: notaTecnica ? notaTecnica : [] });
          });

          // if (props.reloadLista) {
          //   props.reloadLista();
          //   navigate(`/proposicao/acao-preparatoria/${props.acao_preparatoria.id}`)

          // }
          if (props?.reload) {
            props?.reload();
          }
          if (props?.closeMenu) {
            props?.closeMenu();
          }
          setOpen(false);
        } else {
          NewAlert("error", "Verifique os campos e tente outra vez");
        }
      })
      .then((data) => {
        // props?.reload();
        //  props?.onChange()
        //  handleAfterUpload()
      });
  };

  return (
    <div>
      <Link
        href="#"
        onClick={handleClickOpen}
        underline="none"
        style={{
          fontWeight: pallet.fontWeight.subtitle,
          fontSize: `${pallet.general.size14}`,
          color: `${pallet.color.secundary.font}`,
        }}
      >
        {props.children ? props.children : ""}
      </Link>

      <BootstrapDialog
        style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {status !== "Preenchido" && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {titleDialog}
            </BootstrapDialogTitle>

            <Grid
              style={{ fontFamily: pallet.fontFamily.general, width: "100%" }}
            >
              <Grid
                container
                style={{
                  paddingLeft: `${isMobile ? "0px" : "40px"}`,
                  fontWeight: pallet.general.weight_600,
                  alignContent: "center",
                  alignItems: "center",
                  fontFamily: pallet.fontFamily.general,
                  fontSize: pallet.general.size12,
                  paddingTop: "15px",
                }}
              >
                <Grid style={{ marginTop: "4px" }} item>
                  <FeedIcon style={{ color: pallet.general.color1 }} />
                </Grid>
                <Grid style={{ marginLeft: "10px" }} item>
                  AÇÃO PREPARATÓRIA
                </Grid>
                <Grid
                  style={{ marginLeft: "5px", color: pallet.general.color1 }}
                >
                  {" "}
                  {props.acao_preparatoria?.prazo
                    ? formatDate(
                      new Date(props.acao_preparatoria.prazo),
                      "dd/MM/yyyy"
                    )
                    : ""}
                </Grid>
                <Grid style={{ marginLeft: "5px" }}>
                  {" "}
                  | {props.acao_preparatoria?.proposicao}
                </Grid>
              </Grid>
              <Grid>
                <Divider
                  variant="fullWidth"
                  style={{ paddingTop: `${isMobile ? "15px" : "15px"}` }}
                />
              </Grid>


              <Grid
                container
                xl={12}
                lg={12}
                xs={12}
                style={{ paddingTop: "20px" }}
              >
                <Grid
                  style={{
                    marginBottom: "15px",
                    fontWeight: pallet.general.weight_600,
                    fontFamily: pallet.fontFamily.general,
                    fontSize: pallet.general.size12,
                    paddingLeft: `${isMobile ? "5px" : "40px"}`,
                  }}
                >
                  INFORMAÇÕES
                </Grid>

                <Grid
                  container
                  style={{
                    paddingLeft: `${isMobile ? "0px" : "24px"}`,
                    paddingRight: `${isMobile ? "0px" : "24px"}`,
                  }}
                >
                  <GridColumn item md={4} xs={12}>
                    <SelectTextFieldA
                      options={posicionamentosItens}
                      // placeholder={"Selecione departamento ou usuário"}
                      value={posicionamento}
                      onChange={handlePosicionamentoChange}
                      label={"POSICIONAMENTO DO GESTOR"}
                      id={"responsavel-posicionamento"}
                    />
                  </GridColumn>
                  <GridColumn item md={4} xs={12}>
                    <SelectTextFieldA
                      options={impactoFinanceiroItens}
                      // focused={true}
                      placeholder={"Selecione unidade de negócio ou usuário"}
                      value={tipoImpacto}
                      // defaultValue={tipoImpacto}
                      onChange={handleImpactoChange}
                      label={"IMPACTO FINANCEIRO"}
                      id={"impacto-demandas"}
                    />
                  </GridColumn>
                  <GridColumn item md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      type="number"
                      focused={true}
                      // placeholder={"Digite"}
                      value={impacto}
                      onChange={handleImpactoValorChange}
                      label="VALOR DO IMPACTO"
                      id={"impacto-valor-demandas"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                        inputMode: "decimal",
                        style: { fontFamily: `${pallet.general.fontFamily}` },
                        classes: { notchedOutline: classes.notchedOutline },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "gray",
                        },
                      }}
                      style={{ width: "100%" }}
                    />
                  </GridColumn>
                  <GridColumn item md={4} xs={12}>
                    <SelectTextFieldA
                      value={prioridade}
                      onChange={handlePrioridade}
                      options={prioridadeItens}
                      label={"PRIORIDADE"}
                      id={"prioridade-nota-tecnica"}
                    />
                  </GridColumn>
                  {prioridade === "Máxima" && (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{ marginLeft: "16px", paddingTop: "15px" }}
                    >
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={confirmacao}
                              value={confirmacao}
                              onChange={handleConfirmacaoChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Aprovado pela diretoria."
                        />
                      </FormGroup>
                      <Grid
                        style={{
                          fontSize: pallet.general.size12,
                          color: "red",
                        }}
                      >
                        Projetos com prioridade máxima exigem a aprovação da
                        diretoria do seu departamento.
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{
                      marginTop: "20px",
                      paddingLeft: `${isMobile ? "8px" : "16px"}`,
                      paddingRight: `${isMobile ? "8px" : "16px"}`,
                    }}
                  >
                    {/* //   <>
                    //     <InputDropDownCheckBoxNested
                    //       items={departamento}
                    //       onChange={handleSetUserList}
                    //       label={"Solicitacao de"}
                    //       selectedItems={userList}
                    //       lockedItems={lockedUserList}
                    //     />
                    //   </> */}

                    <InputMultilineTextField
                      type={"text"}
                      maxRows={6}
                      minRows={4}
                      value={justificativa}
                      onChange={handleJustificativaChange}
                      id={"justificativa"}
                      label="ARGUMENTOS PARA JUSTIFICAR POSICIONAMENTO"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{
                      marginTop: "40px",
                      paddingLeft: `${isMobile ? "8px" : "16px"}`,
                      paddingRight: `${isMobile ? "8px" : "16px"}`,
                    }}
                  >
                    {departamento && (

                      <>
                        <Grid
                          style={{
                            marginBottom: "15px",
                            fontWeight: pallet.general.weight_600,
                            fontFamily: pallet.fontFamily.general,
                            fontSize: pallet.general.size12,
                            // paddingLeft: `${isMobile ? "5px" : "40px"}`,
                          }}
                        >
                          DESEJA INCLUIR OUTRAS UNIDADES DE NEGÓCIO/RESPONSÁVEIS PARA ESTA NOTA TÉCNICA ?
                        </Grid>

                        <InputDropDownCheckBoxNested
                          items={departamento}
                          onChange={handleSetUserList}
                          label={"Solicitacao de"}
                          selectedItems={userList}
                          lockedItems={lockedUserList}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Divider
                  variant="fullWidth"
                  style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }}
                />
              </Grid>
              <Grid
                style={{
                  paddingTop: `${isMobile ? "15px" : "40px"}`,
                  paddingLeft: `${isMobile ? "10px" : "24px"}`,
                  paddingRight: `${isMobile ? "0px" : "24px"}`,
                }}
              >
                <Grid
                  xs={12}
                  lg={12}
                  style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                >
                  <Grid
                    style={{
                      fontWeight: pallet.fontWeight.subtitle,
                      fontSize: `${pallet.general.size12}`,
                      color: `${pallet.color.tertiary.font}`,
                    }}
                  >
                    ANEXOS
                  </Grid>

                  <Grid style={{ marginTop: "10px" }}>
                    <Grid className="container">
                      <AnexosComponent
                        label=""
                        modulo={EAnexoModulos.NotaTecnica}
                        id_referencia={props.nota_tecnica.id}
                        //onAfterUpload={handleAfterUpload}
                        canEdit={true}
                        autosave={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
                loading ?
                <CircularLoading
                    size={100}
                    top={isMobile ? "37%" : "90%"}
                    left={isMobile ? "6%" : "16%"}
                    zIndex={2}
                />

                  :
                  null
              }
            <Grid>
              <Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{
                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                height: `${isMobile ? "50px" : "98px"}`,
                borderRadius: "5px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  style={{
                    paddingRight: `${isMobile ? "20px" : "40px"}`,
                    textAlignLast: "end",
                    padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"
                      }`,
                  }}
                >
                  <SolidButton
                    onClick={salvarPosicionamento}
                    color={`${pallet.textColorPrimary}`}
                    // onChange={props?.reloadLista}
                    backgroundColor={`${pallet.backgroundColorPrimary}`}
                    fontSize={`${pallet.general.size14}`}
                    title={"SALVAR"}
                    disabled={lockSaveButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}
