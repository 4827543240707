import styled from "styled-components";

import { Button } from '@mui/material';

import { usePallet } from '../contexts/PalletContext';


const ButtonLayout = styled(Button)`
  height: 35px;
  margin-top: 8px;
  text-transform: capitalize;
  font-family: ${(props => props.pallet.fontFamily.general)};
  font-weight: ${(props => props.pallet.general.weight_bold)};
  font-size: ${(props => props.pallet.general.size12)};

`;

interface IProps {
  id: number,
  status: boolean,
  onClick: () => void,
  marginRight?: any,
  width?: any
};

export default function ButtonSeguir(props: IProps) {
  const { pallet } = usePallet();

  const handleClick = () => {
    props.onClick();
  };

  return (
    <>
      <ButtonLayout
        onClick={() => handleClick()}
        variant="contained"
        pallet={pallet}
        disableElevation
        style={{
          marginRight: props.marginRight,
          marginLeft: "10px",
          width: props.width ? props.width : "100%",
          backgroundColor: `${props.status ? pallet.color.colorBase.white : pallet.color.primary.background} `,
          border: `1px solid ${props.status ? pallet.color.primary.background : "none"} `,
          color: `${props.status ? pallet.color.secundary.font : pallet.color.colorBase.white} `,
        }}
      >
        {props.status ? "SEGUINDO" : "SEGUIR"}
      </ButtonLayout>
    </>
  );
}