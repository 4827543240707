import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import sty from "styled-components";
import useStyles from '../../../../components/layout/Styles';
import { usePallet } from '../../../../contexts/PalletContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import Paper from '@mui/material/Paper';
import { formatDate } from '../../../../utils/dateUtils';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import AttachmentIcon from '@mui/icons-material/Attachment';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import { useAlert } from '../../../../contexts/AlertContext';
import ProposicaoAcaoDeInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';

export interface IAcaoInfluenciaItem {
    ambitoId?: number,
    casa_legislativa?: number,
    descricao?: string,
    acao_influencia_id: number,
    acao_influencia_prazo: Date,
    acao_influencia_responsavel: string,
    resultado: number,
    ambitoSigla: string,
    ambitoDescricao: string,
    influenciados?: Array<{
        id:  number
        nome: string
    }>
    reloadList?: () => void,
    // closeMenu?: () => void,
}

const resultadoItens = [
    { key: 1, label: "Pendente", value: "Pendente" },
    { key: 2, label: "Êxito", value: "Êxito" },
    { key: 3, label: "Sem êxito", value: "Sem êxito" },
    { key: 4, label: "Êxito parcial", value: "Êxito parcial" },
]

const DotPosicionamento = sty.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "10px"};
`;

function AccordionTextDataAcaoInfluenciaDetail(props: IAcaoInfluenciaItem): JSX.Element {
    const { pallet } = usePallet();
    const classes = useStyles();
    const [idReferencia, setIdReferencia] = React.useState<number>();
    const { NewAlert } = useAlert();
    const handleReferencia = () => { idReferencia ? setIdReferencia(undefined) : setIdReferencia(57); }
    const [resultado, setResultado] = useState<string>(String(props?.resultado));

    // const [registro, setRegistro] = useState(props.resultado ? props.resultado : "Pendente");

    useEffect(() => {
        setResultado(String(props.resultado))
    }, [])

    const corResultado = (value: string) => {
        switch (value) {
            case "semExito":
                return `${pallet.charts.red}`;
            case "pendente":
                return `${pallet.charts.yellow}`;
            case "exitoParcial":
                return `${pallet.charts.blue}`;
            case "exito":
                return `${pallet.charts.green}`;
            default:
                return 'white';
        }
    };


    function LabelHandleResultado(props: {
        resultado: string;
        bg: string;
    }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corResultado(`${props?.bg}`)} />
                    </Grid>
                    <Grid item>{props?.resultado}</Grid>
                </Grid>
            </>
        );
    }


    const resultadoItens = [
        {
            key: 1,
            label: (
                <LabelHandleResultado
                    resultado={"Pendente"}
                    bg={"pendente"}
                />
            ),
            value: 4,
        },
        {
            key: 2,
            label: (
                <LabelHandleResultado
                    resultado={"Sem Êxito"}
                    bg={"semExito"}
                />
            ),
            value: 2,
        },
        {
            key: 3,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito Parcial"}
                    bg={"exitoParcial"}
                />
            ),
            value: 3,
        },
        {
            key: 4,
            label: (
                <LabelHandleResultado
                    resultado={"Êxito"}
                    bg={"exito"}
                />
            ),
            value: 1,
        },
    ];

    const CustomLabelResultado: React.FunctionComponent = () => {

        return (
            <div style={{
                color: `${pallet.color.colorBase.labelTextFild}`,
                fontFamily: `${pallet.fontFamily.general}`,
                fontSize: "12px"
            }}>
                RESULTADO
            </div>
        )
    }

    const BoxResponsavel = sty(Grid)`
    direction: ${isMobile ? "column" : "row"};
    background: ${pallet.backGroundPageColorPrimary};
    padding-left: ${isMobile ? "5px" : "20px"};
    min-height: 63px;
    box-sizing: border-box;
    border: 1px solid #EAEAEA;
    align-items: center;
    flex-direction:  ${isMobile ? "column" : "row"};; 
`;

    const ChipDepartamento = sty(Grid)`
    background-color: #E0CDF8;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 12px;
    color: ${(pallet.general.color4)};
    height: ${isMobile ? "max-content" : "31px"};;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    text-align: ${isMobile ? "center" : "none"};
    width: max-content ;
    max-width: ${isMobile ? "220px" : "max-content"};
    font-weight: ${pallet.fontWeight.subtitle};
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    margin-bottom: ${isMobile ? "5px" : "0px"};
    margin-top: ${isMobile ? "5px" : "0px"};
`;

    const NomeResponsavel = sty.div`
    font-size: ${pallet.general.size14};
    color: ${pallet.color.secundary.font};
    padding-left: 10px;
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    line-height: 17px;
    text-align: ${isMobile ? "center" : "none"};
    letter-spacing: 1px;
    text-align-last: ${isMobile ? "center" : ""} ;
    margin-inline-end: ${isMobile ? "" : "auto"};
    min-width: 200px;
    margin-bottom: ${isMobile ? "5px" : "0px"};
`;

    const CheckedIcon = sty(CheckIcon)`
    color: #4CD964;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    min-width:30px;
`;

    const DangerIcon = sty(ReportProblemIcon)`
    color: #F63854;
    font-size: ${pallet.general.size18};
    font-weight: ${pallet.general.weight_600};
    margin-right: 10px;
    min-width:30px;
`;

    const Status = sty.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.tertiary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    position: relative;
    margin-right: 15px;
`;


    const handleResultadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResultado(event.target.value);
        let novoResultado = Number(event.target.value)


        ProposicaoAcaoDeInfluenciaService.UpdateResultado(props?.acao_influencia_id, novoResultado).then(res => {
            if (res) {
                NewAlert('success', 'Resultado salvo com sucesso');
            } else {
                NewAlert('error', 'Não foi possível salvar o resultado');
            }
        })
            .then(data => {
                // props?.onChange()
                // handleAfterUpload()
                props.reloadList()
            })
    }

    return (
        <>
            <Grid key={0} style={{ marginBottom: "10px"}}>
                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 10px 15px"}`, width: "100%", minWidth: '100%' }}>
                    <Grid container style={{ paddingTop: "10px" }}>
                        <Grid container whiteSpace={"normal"} spacing={5} textOverflow={"ellipsis"} xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid lg={4} item>
                                <Grid item style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "8px"
                                }}>
                                    RESPONSÁVEL
                                </Grid>

                                <Typography style={{
                                    width: `${isMobile ? "100%" : "100%"}`,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    overflowWrap: "break-word"
                                }}>
                                    {props?.acao_influencia_responsavel}
                                </Typography>
                            </Grid>
                            <Grid lg={4} item>
                                <Grid item style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "8px",
                                }}>
                                    PRAZO
                                </Grid>
                                <Typography style={{
                                    width: `${isMobile ? "100%" : "100%"}`,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    overflowWrap: "break-word"
                                }}>
                                    {formatDate(new Date(props?.acao_influencia_prazo), 'dd/MM/yyyy')}
                                </Typography>
                            </Grid>
                            <Grid lg={4} item>
                                <Grid item style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "8px",
                                }}>
                                    CASA LEGISLATIVA
                                </Grid>
                                <Typography style={{
                                    width: `${isMobile ? "100%" : "100%"}`,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    overflowWrap: "break-word"
                                }}>
                                    {props?.casa_legislativa === 1 ? "Senado Federal" :
                                        props?.casa_legislativa === 2 ? "Câmara dos Deputados" :
                                            props?.casa_legislativa === 3 ? "Congresso Nacional" :
                                                props?.casa_legislativa === 4 ? "Assembléias Legislativas" :
                                                    "Câmaras Municipais"
                                    }
                                </Typography>
                            </Grid>
                            <Grid lg={4} item>
                                <Grid item style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "8px"
                                }}>
                                    INFLUÊNCIA SOBRE
                                </Grid>

                                <Typography style={{
                                    width: `${isMobile ? "100%" : "100%"}`,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    overflowWrap: "break-word"
                                }}>
                                    {props?.influenciados.map(x => {
                                        return (
                                            <span >
                                                <a style={{textDecoration: 'none',color:`${pallet.color.tertiary.font}`}} href={"/interlocutor/" + x.id} target='_blank'>{x.nome}</a>{props?.influenciados.length > 1 ? (", ") : null}
                                            </span>
                                        )
                                    })}
                                </Typography>
                            </Grid>

                            {props?.ambitoSigla !== null && props?.ambitoDescricao !== null
                                ?
                                <Grid lg={4} item>
                                    <Grid item style={{
                                        fontWeight: pallet.fontWeight.subtitle,
                                        fontSize: `${pallet.general.size12}`,
                                        color: `${pallet.color.tertiary.font}`,
                                        paddingBottom: "8px",
                                    }}>
                                        ÂMBITO
                                    </Grid>
                                    <Typography style={{
                                        width: `${isMobile ? "100%" : "100%"}`,
                                        fontSize: `${pallet.general.size14}`,
                                        color: `${pallet.color.tertiary.font}`,
                                        overflowWrap: "break-word"
                                    }}>
                                        {props?.ambitoSigla + " - " + props?.ambitoDescricao}
                                    </Typography>
                                </Grid>
                                :
                                null
                            }

                            <Grid lg={12} item>
                                <Grid item style={{
                                    fontWeight: pallet.fontWeight.subtitle,
                                    fontSize: `${pallet.general.size12}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    paddingBottom: "8px",
                                }}>
                                    DESCRIÇÃO
                                </Grid>
                                <Typography style={{
                                    width: `${isMobile ? "100%" : "100%"}`,
                                    fontSize: `${pallet.general.size14}`,
                                    color: `${pallet.color.tertiary.font}`,
                                    overflowWrap: "break-word"
                                }}>
                                    {props?.descricao}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" style={{ paddingTop: "30px", }} />

                    <Grid md={5} xs={12} style={{ paddingTop: "20px" }}>

                        <Grid style={{
                            fontWeight: pallet.fontWeight.subtitle,
                            fontSize: `${pallet.general.size12}`,
                            color: `${pallet.color.tertiary.font}`,
                            paddingBottom: "20px"
                        }}>
                            REGISTRO
                        </Grid>
                        <Grid>
                            <Box component="form">
                                <SelectTextFieldA
                                    options={resultadoItens}
                                    value={resultado}
                                    onChange={handleResultadoChange}
                                    // defaultValue={resultadoGetDefault}
                                    label={"RESULTADO"}
                                    id={"resultado-acaoInfluencia"}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" style={{ paddingTop: "30px", marginBottom: "30px" }} />

                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                        <Grid className="container">
                            <AnexosComponent
                                label={''}
                                // id_referencia={props.idRef}
                                id_referencia={props?.acao_influencia_id}
                                modulo={EAnexoModulos.ProposicaoAcaoInfluencia}
                                canEdit={false}
                                autosave={false}
                                isAnexosTitle={"ANEXOS"}
                            />
                        </Grid>
                    </Grid>




                </Grid>
            </Grid >
        </>
    );
}

export default AccordionTextDataAcaoInfluenciaDetail;

