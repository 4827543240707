import React from 'react';
import CarouselCustom from '../../../components/carousel/CarouselCustom';
import ParlamentarCarouselTabsAcontecimentos from '../../parlamentar/_components/ParlamentarCarouselTabsAcontecimentos';
import ParlamentarCarouselTabsAcoesInfluencia from '../../parlamentar/_components/ParlamentarCarouselTabsAcoesInfluencia';
import ParlamentarCarouselTabsDemandas from '../../parlamentar/_components/ParlamentarCarouselTabsDemandas';
import { IParlamentar } from '../../../models/Parlamentar.Model';
import { IUser } from '../../../models/User.Model';
import GerUsuariosCarouselTabsUsuarios from './GerUsuariosCarouselTabsUsuarios';
import GerUsuariosCarouselTabsDepartamentos from './GerUsuariosCarouselTabsDepartamentos';
import GerUsuariosCarouselTabsHistAtividades from './GerUsuariosCarouselTabsHistAtividades';

export default function GerUsuariosCarouselTabs(props: { id_user: number, usuario?: IUser, query: string,onChange: () => void }) {
    const [activeTab, setActiveTab] = React.useState(props.query ? 3 : 0);

    const itens = [
        { index: 0, hide: false, label: "Usuários", component: <GerUsuariosCarouselTabsUsuarios key={0} index={0} activeTab={activeTab} id_user={props.id_user} onChange={props.onChange} /> },
        { index: 1, hide: false, label: "Unidades de negócio", component: <GerUsuariosCarouselTabsDepartamentos key={1} index={1} activeTab={activeTab} id_user={props.id_user} /> },
        { index: 2, hide: false, label: "Histórico de Atividades", component: <GerUsuariosCarouselTabsHistAtividades key={2} index={2} activeTab={activeTab} id_user={props.id_user} /> },        
    ]

    
    return (
        <CarouselCustom itens={itens} activeTab={activeTab} onChange={setActiveTab} />
    );
}